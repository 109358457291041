import { useEffect } from "react";
import { StyledTermsMainDiv } from "../styledComponents/TermsAndConditions.styled";

export default function Terms() {
  useEffect(() => {
    window.scrollTo(0, 0);
  }, []);
  return (
    <StyledTermsMainDiv>
      <h1>TERMS AND CONDITIONS</h1>
      Last updated: October 22, 2024
      <br />
      <br />
      This website is operated by WheresOur ("we," "us," "our", “Provider”).
      <br />
      <br />
      We operate the website www.wheresour.com (the "Site"), as well as any
      other related products and services that refer or link to these legal
      terms (the "Legal Terms") (collectively, the "Services").
      <br />
      <br />
      We are an organization and home inventory application built to help users
      organize their belongings.
      <br />
      <br />
      You can contact us by email at admin@wheresour.com.
      <br />
      <br />
      By visiting our site and/ or creating an account, you (“you”, “Customer”)
      engage in our “Service” and agree to be bound by the following terms and
      conditions (“Terms and Conditions”, “Terms”). These Terms of Service apply
      to all users of the site.
      <br />
      <br />
      Please read these Terms of Service carefully before accessing or using our
      website. By accessing or using any part of the site, you agree to be bound
      by these Terms of Service. If you do not agree to all the terms and
      conditions of this agreement, then you may not access the website or use
      any Services.
      <br />
      <br />
      Any modified Terms and Conditions will become effective upon posting on
      the site OR notifying you by admin@wheresour.com. You agree to be bound by
      the modified Terms and Conditions by continuing to use the Services after
      the effective date of any changes.
      <br />
      <br />
      By agreeing to these Terms of Service, you represent that you are at least
      the age of majority in your state or province of residence, or that you
      are the age of majority in your state or province of residence and you
      have given us your consent to allow any of your minor dependents to use
      this site.
      <br />
      <h2>1. HOSTED SERVICES</h2>
      The Services are not tailored to comply with industry-specific
      regulations, so if your interactions would be subjected to such laws, you
      may not use the Services.
      <br />
      Except to the extent expressly permitted in this Agreement or required by
      law on a non-excludable basis, the Customer is subject to the following
      prohibitions:
      <ul>
        <li>
          The Customer must not sub-license its right to access and use the
          Hosted Services;
        </li>
        <li>
          The Customer must not permit any unauthorized person or application to
          access or use the Hosted Services;
        </li>
        <li>
          The Customer must not conduct or request that any other person conduct
          any load testing or penetration testing on the Platform or Hosted
          Services without the prior written consent of the Provider.
        </li>
      </ul>
      You are required to register to use the Services. You agree to keep your
      password confidential and will be responsible for all use of your account
      and password.
      <br /> The Customer must not use the Hosted Services:
      <ul>
        <li>In any way that is unlawful, illegal, fraudulent or harmful; or</li>
        <li>
          In connection with any unlawful, illegal, fraudulent or harmful
          purpose or activity.
        </li>
      </ul>
      For the avoidance of doubt, the Customer has no right to access the
      software code (including object code, intermediate code and source code)
      of the Platform, either during or after the Term.
      <br />
      By using the Services, you represent and warrant that:
      <ul>
        <li>
          you have the legal capacity and you agree to comply with these Legal
          Terms;
        </li>
        <li>you are not a minor in the jurisdiction in which you reside;</li>
        <li>
          you will not access the Services through automated or non-human means,
          whether through a bot, script or otherwise;
        </li>
        <li>
          you will not use the Services for any illegal or unauthorized purpose;
          and
        </li>
        <li>
          your use of the Services will not violate any applicable law or
          regulation.
        </li>
      </ul>
      <h2>2. INTELLECTUAL PROPERTY RIGHTS</h2>
      Nothing in this Agreement shall operate to assign or transfer any
      Intellectual Property Rights from the Provider to the Customer.
      <h3>Submissions:</h3> By submitting data on the Site you agree to assign
      us ownership of the submission (Including images, pictures, and any other
      content). We are entitled to unrestricted use for any lawful purpose,
      commercial or otherwise, without acknowledgment or compensation to you.
      <h3>You are responsible for what you upload:</h3>
      You are solely responsible for your Submissions and you expressly agree to
      reimburse us for any and all losses that we may suffer because of your
      breach of (a) this section, (b) any third party’s intellectual property
      rights, or (c) applicable law.
      <h3>We may remove or edit your Submissions:</h3>
      We retain the right to remove or edit any Submissions at any time without
      notice if in our reasonable opinion we consider such Submissions harmful
      or in breach of these Legal Terms. If we remove or edit any such
      Submissions, we may also suspend or disable your account and report you to
      the authorities.
      <h2>3. PURCHASES AND PAYMENT</h2>
      We accept payment through Stripe, Inc.
      <br />
      <br />
      You agree to provide current, complete, and accurate purchase and account
      information for all purchases made via the Services. You further agree to
      promptly update account and payment information, payment method, and
      payment card expiration date, so that we can complete your transactions
      and contact you as needed. Sales tax will be added when deemed necessary.
      All payments shall be in US dollars.
      <br />
      <br />
      You agree to pay all charges at the prices then in effect for your
      subscription, and you authorize us to charge your chosen payment provider
      for any such amounts. We reserve the right to correct any errors or
      mistakes in pricing, even if we have already requested or received
      payment.
      <h2>4. SUBSCRIPTIONS</h2>
      <h3>Billing and Renewal:</h3> Your subscription will continue and
      automatically renew unless canceled. You consent to our charging your
      payment method on a recurring basis without requiring your prior approval
      for each recurring charge, until such time as you cancel the applicable
      order. The length of your billing cycle is every three (3) months.
      <h3>Free Trial:</h3> We offer a 14-day free trial to new users who
      register with the Services. The account will not be charged and the
      subscription will be suspended until upgraded to a paid version at the end
      of the free trial.
      <h3>Missed Payment and Lack of Payment:</h3>
      Subscription payments are required at the beginning of the three (3) month
      subscription period. Customers who miss a payment at the beginning of the
      period will lose access to their account until the subscription is
      renewed. Please see the “Data Retention” section for information on our
      policy regarding account data.
      <h3>Cancellation:</h3>
      All purchases are non-refundable. You can cancel your subscription at any
      time by logging into your account. Your cancellation will take effect at
      the end of the current paid term.
      <h3>Fee Changes:</h3> We may, from time to time, make changes to the
      subscription fee and will communicate any price changes to you in
      accordance with applicable law.
      <h3>Data Retention:</h3>
      We will make an effort to retain subscriber data for a minimum of one (1)
      month. After that time we reserve the right to delete Customer data.
      <h3>Submission Limits:</h3>A single account (paid subscription or free
      trial) is limited to a maximum of 5,000 active submissions (also known as
      “Items”). We reserve the right to request additional payment from you in
      the event that these limits are exceeded.
      <h2>5. PROHIBITED ACTIVITIES</h2>
      In addition to other prohibitions as set forth in the Terms of Service,
      you are prohibited from using the site or its content:
      <ul>
        <li>for any unlawful purpose;</li>
        <li>
          to solicit others to perform or participate in any unlawful acts;
        </li>
        <li>
          to violate any international, federal, provincial or state
          regulations, rules, laws, or local ordinances;
        </li>
        <li>
          to infringe upon or violate our intellectual property rights or the
          intellectual property rights of others;
        </li>
        <li>
          to harass, abuse, insult, harm, defame, slander, disparage,
          intimidate, or discriminate based on gender, sexual orientation,
          religion, ethnicity, race, age, national origin, or disability;
        </li>
        <li>to submit false or misleading information;</li>
        <li>
          to upload or transmit viruses or any other type of malicious code that
          will or may be used in any way that will affect the functionality or
          operation of the Service or of any related website, other websites, or
          the Internet;
        </li>
        <li>to collect or track the personal information of others;</li>
        <li>to spam, phish, pharm, pretext, spider, crawl, or scrape;</li>
        <li>
          for any obscene or immoral purpose; or (k) to interfere with or
          circumvent the security features of the Service or any related
          website, other websites, or the Internet. We reserve the right to
          terminate your use of the Service or any related website for violating
          any of the prohibited uses.
        </li>
      </ul>
      <h2>6. SERVICES MANAGEMENT</h2>
      We reserve the right, but not the obligation, to:
      <ul>
        <li>monitor the Services for violations of these Legal Terms;</li>
        <li>
          take appropriate legal action against anyone who, in our sole
          discretion, violates the law or these Legal Terms, including without
          limitation, reporting such user to law enforcement authorities;
        </li>
        <li>
          in our sole discretion and without limitation, refuse, restrict access
          to, limit the availability of, or disable (to the extent
          technologically feasible) your access to the Site;
        </li>
        <li>
          in our sole discretion and without limitation, notice, or liability,
          to remove from the Services or otherwise disable all files and content
          that are excessive in size or are in any way burdensome to our
          systems; and
        </li>
        <li>
          otherwise manage the Services in a manner designed to protect our
          rights and property and to facilitate the proper functioning of the
          Services.
        </li>
      </ul>
      <h2>7. PRIVACY POLICY</h2>
      Your submission of personal information through the store is governed by
      our Privacy Policy. To view our Privacy Policy, please see
      www.WheresOur.com/privacy.
      <h2>8. USER COMMENTS, FEEDBACK AND OTHER SUBMISSIONS</h2>
      If, at our request, you send certain specific submissions (for example
      contest entries) or without a request from us you send creative ideas,
      suggestions, proposals, plans, or other materials, whether online, by
      email, by postal mail, or otherwise (collectively, 'comments'), you agree
      that we may, at any time, without restriction, edit, copy, publish,
      distribute, translate and otherwise use in any medium any comments that
      you forward to us. We are and shall be under no obligation
      <ul>
        <li>to maintain any comments in confidence;</li>
        <li>to pay compensation for any comments; or</li>
        <li>to respond to any comments.</li>
      </ul>
      We may, but have no obligation to, monitor, edit or remove content that we
      determine in our sole discretion to be unlawful, offensive, threatening,
      libelous, defamatory, pornographic, obscene or otherwise objectionable or
      violates any party’s intellectual property or these Terms of Service.
      <br />
      You agree that your comments will not violate any right of any
      third-party, including copyright, trademark, privacy, personality or other
      personal or proprietary right. You further agree that your comments will
      not contain libelous or otherwise unlawful, abusive or obscene material,
      or contain any computer virus or other malware that could in any way
      affect the operation of the Service or any related website. You may not
      use a false e-mail address, pretend to be someone other than yourself, or
      otherwise mislead us or third-parties as to the origin of any comments.
      You are solely responsible for any comments you make and their accuracy.
      We take no responsibility and assume no liability for any comments posted
      by you or any third-party.
      <h2>9. TERM AND TERMINATION</h2>
      These Legal Terms shall remain in full force and effect while you use the
      Services. WITHOUT LIMITING ANY OTHER PROVISION OF THESE LEGAL TERMS, WE
      RESERVE THE RIGHT TO, IN OUR SOLE DISCRETION AND WITHOUT NOTICE OR
      LIABILITY, DENY ACCESS TO AND USE OF THE SERVICES (INCLUDING BLOCKING
      CERTAIN IP ADDRESSES), TO ANY PERSON FOR ANY REASON OR FOR NO REASON,
      INCLUDING WITHOUT LIMITATION FOR BREACH OF ANY REPRESENTATION, WARRANTY,
      OR COVENANT CONTAINED IN THESE LEGAL TERMS OR OF ANY APPLICABLE LAW OR
      REGULATION. WE MAY TERMINATE YOUR USE OR PARTICIPATION IN THE SERVICES OR
      DELETE YOUR ACCOUNT AND ANY CONTENT OR INFORMATION THAT YOU POSTED AT ANY
      TIME, WITHOUT WARNING, IN OUR SOLE DISCRETION.
      <br />
      <br />
      If we terminate or suspend your account for any reason, you are prohibited
      from registering and creating a new account under your name, a fake or
      borrowed name, or the name of any third party, even if you may be acting
      on behalf of the third party. In addition to terminating or suspending
      your account, we reserve the right to take appropriate legal action,
      including without limitation pursuing civil, criminal, and injunctive
      redress.
      <h2>10. MODIFICATIONS AND INTERRUPTIONS</h2>
      WheresOur reserves the right to change, modify, or remove the contents of
      the Services at any time or for any reason at our sole discretion without
      notice. However, we have no obligation to update any information on our
      Services. We will not be liable to you or any third party for any
      modification, price change, suspension, or discontinuance of the Services.
      <br />
      <br />
      We cannot guarantee the Services will be available at all times. We may
      experience hardware, software, or other problems or need to perform
      maintenance related to the Services, resulting in interruptions, delays,
      or errors. We reserve the right to change, revise, update, suspend,
      discontinue, or otherwise modify the Services at any time or for any
      reason without notice to you. You agree that we have no liability
      whatsoever for any loss, damage, or inconvenience caused by your inability
      to access or use the Services during any downtime or discontinuance of the
      Services. You also agree that we have no liability for any loss of data
      related to the Services. Nothing in these Legal Terms will be construed to
      obligate us to maintain and support the Services or to supply any
      corrections, updates, or releases in connection therewith.
      <h2>11. GOVERNING LAW</h2>
      These Terms of Service and any separate agreements whereby we provide you
      Services shall be governed by and construed in accordance with the laws of
      United States.
      <h2>12. DISPUTE RESOLUTION</h2>
      Any legal action of whatever nature brought by either you or us
      (collectively, the "Parties" and individually, a "Party") shall be
      commenced or prosecuted in the state and federal courts located in North
      Carolina. Any claim, action, or proceeding brought by either Party related
      in any way to the Services should be commenced within one (1) year after
      the cause of action arose.
      <h2>13. CORRECTIONS</h2>
      There may be information on the Services that contains typographical
      errors, inaccuracies, or omissions, including descriptions, pricing,
      availability, and various other information. We reserve the right to
      correct any errors, inaccuracies, or omissions and to change or update the
      information on the Services at any time, without prior notice.
      <h2>14. DISCLAIMER OF WARRANTIES; LIMITATION OF LIABILITY</h2>
      We do not guarantee, represent or warrant that your use of our service
      will be uninterrupted, timely, secure or error-free.
      <br />
      <br />
      We do not warrant that the results that may be obtained from the use of
      the service will be accurate or reliable.
      <br />
      <br />
      You agree that from time to time we may remove the service for indefinite
      periods of time or cancel the service at any time, without notice to you.
      You expressly agree that your use of, or inability to use, the service is
      at your sole risk. The service and all products and Services delivered to
      you through the service are (except as expressly stated by us) provided
      'as is' and 'as available' for your use, without any representation,
      warranties or conditions of any kind, either express or implied, including
      all implied warranties or conditions of merchantability, merchantable
      quality, fitness for a particular purpose, durability, title, and
      non-infringement.
      <br />
      <br />
      In no case shall WheresOur, our directors, officers, employees,
      affiliates, agents, contractors, interns, suppliers, service providers or
      licensors be liable for any injury, loss, claim, or any direct, indirect,
      incidental, punitive, special, or consequential damages of any kind,
      including, without limitation lost profits, lost revenue, lost savings,
      loss of data, replacement costs, or any similar damages, whether based in
      contract, tort (including negligence), strict liability or otherwise,
      arising from your use of any of the service or any products procured using
      the service, or for any other claim related in any way to your use of the
      service or any product, including, but not limited to, any errors or
      omissions in any content, or any loss or damage of any kind incurred as a
      result of the use of the service or any content (or product) posted,
      transmitted, or otherwise made available via the service, even if advised
      of their possibility.
      <br />
      <br />
      Because some states or jurisdictions do not allow the exclusion or the
      limitation of liability for consequential or incidental damages, in such
      states or jurisdictions, our liability shall be limited to the maximum
      extent permitted by law.
      <h2>15. LIMITATIONS OF LIABILITY</h2>
      <ul>
        <li>
          Indirect Damages: To the maximum extent permitted by Law, we will not
          be liable to you in relation to this Agreement or the Services during
          and after the Term, whether in contract, negligence, strict liability,
          tort or other legal or equitable theory, for any lost profits,
          personal injury, property damage, loss of data, business interruption,
          indirect, incidental, consequential, exemplary, special, reliance, or
          punitive damages, even if these losses, damages, or costs are
          foreseeable, and whether or not you or the Stripe Parties have been
          advised of their possibility.
        </li>
        <li>
          General Damages: To the maximum extent permitted by Law, the Stripe
          Parties will not be liable to you in relation to this Agreement or the
          Services during and after the Term, whether in contract, negligence,
          strict liability, tort or other legal or equitable theory, for losses,
          damages, or costs exceeding in the aggregate the greater of (i) the
          total amount of fees you paid to WheresOur during the 3-month period
          immediately preceding the event giving rise to the liability; and (ii)
          $20 USD.
        </li>
      </ul>
      <h2>16. INDEMNIFICATION</h2>
      You agree to indemnify, defend and hold harmless WheresOur and our parent,
      subsidiaries, affiliates, partners, officers, directors, agents,
      contractors, licensors, service providers, subcontractors, suppliers,
      interns and employees, harmless from any claim or demand, including
      reasonable attorneys’ fees, made by any third-party due to or arising out
      of your breach of these Terms of Service or the documents they incorporate
      by reference, or your violation of any law or the rights of a third-party.
      <h2>17. SEVERABILITY</h2> In the event that any provision of these Terms
      of Service is determined to be unlawful, void or unenforceable, such
      provision shall nonetheless be enforceable to the fullest extent permitted
      by applicable law, and the unenforceable portion shall be deemed to be
      severed from these Terms of Service, such determination shall not affect
      the validity and enforceability of any other remaining provisions.
      <h2>18. USER DATA</h2> You agree that we have no liability to you for any
      loss or corruption of any data, and you hereby waive any right of action
      against us arising from any such loss or corruption of such data.
      <h2>19. CONTACT INFORMATION</h2> In order to resolve a complaint regarding
      the Services or to receive further information regarding use of the
      Services, please contact us at: admin@wheresour.com
    </StyledTermsMainDiv>
  );
}
