import "./App.css";
import { Header } from "./components/Header";
import { HeaderNav } from "./components/HeaderNav";
import FAQ from "./pages/FAQ";
import MyItems from "./pages/MyItems";
import { Route, Routes } from "react-router-dom";
import { LoginStageContextProvider } from "./Contexts/LoginStageContext";
import { useEffect } from "react";
import { cognitoUserAttributes, useAccountContext } from "./Contexts/Account";
import { HeaderNavFAQ } from "./components/HeaderNavFAQ";
import { Elements } from "@stripe/react-stripe-js";
import { loadStripe } from "@stripe/stripe-js";
import Home from "./pages/Home";
import Privacy from "./pages/Privacy";
import Terms from "./pages/TermsAndConditions";
import { Footer } from "./components/Footer";



function App() {
  const {
    loggedInUserCognitoIdToken,
    setLoggedInUserCognitoIdToken,
    setUserCognitoAttrubutes,
  } = useAccountContext();

  useEffect(() => {
    if (loggedInUserCognitoIdToken == "") {
      const attributesString = window.localStorage.getItem(
        "UserCognitoAttrubutes"
      );
      if (attributesString != null) {
        const attributes: cognitoUserAttributes = JSON.parse(
          attributesString
        ) as cognitoUserAttributes;
        const id = window.localStorage.getItem("CognitoIdToken");
        setUserCognitoAttrubutes(attributes);
        if (id != null) {
          setLoggedInUserCognitoIdToken(id);
        }
      }
    }
  }, []);
  return (
    <>
      <LoginStageContextProvider>
        <Header />
      </LoginStageContextProvider>
      <div className="container">
        <Routes>
          <Route
            path="/"
            element={
              <>
                <HeaderNavFAQ />
                <Home />
                <Footer/>
              </>
            }
          />
          <Route
            path="/Home"
            element={
              <>
                <HeaderNavFAQ />
                <Home />
                <Footer/>
              </>
            }
          />
          <Route
            path="/FAQ"
            element={
              <>
                <HeaderNavFAQ />
                <FAQ />
                <Footer/>
              </>
            }
          />
          <Route
            path="/MyItems"
            element={
              <>
                <HeaderNav />
                <MyItems alrt={null}  />
              </>
            }
          />
          <Route
            path="/success"
            element={
              <>
                <HeaderNav />
                <MyItems alrt={"success"} />
              </>
            }
          />
          <Route
            path="/cancel"
            element={
              <>
                <HeaderNav />
                <MyItems alrt={"cancel"} />
              </>
            }
          />
          <Route
            path="/Privacy"
            element={
              <>
                <HeaderNavFAQ />
                <Privacy/>
                <Footer/>
              </>
            }
          />
          <Route
            path="/Terms"
            element={
              <>
                <HeaderNavFAQ />
                <Terms/>
                <Footer/>
              </>
            }
          />
        </Routes>
      </div>
    </>
  );
}

export default App;
