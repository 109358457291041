import {
  StyledHeader,
  Logo,
  Nav,
  Phrase,
} from "../styledComponents/Header.styled";
import { Container } from "../styledComponents/Containers.styled";
import {
  Button,
  Buttons,
  LoginButton,
  SubscriptionExpiredButton,
} from "../styledComponents/Buttons.styled";
import { useAccountContext } from "../Contexts/Account";
import { useLoginStageContext } from "../Contexts/LoginStageContext";
import LoginFlowPopup from "../Popups/LoginFlowPopup";
import { useUserDataContext } from "../Contexts/UserDataContext";
import { useEffect } from "react";
import { checkInactivity, updateExpTime } from "../utilities/Inactivity";

export function Header() {
  const { loggedInUserCognitoIdToken } = useAccountContext();
  const { setLoginStage } = useLoginStageContext();
  const { logoutUser, setCheckoutPopupOpen, subscriptionExpired, subscriptionStatus } =
    useUserDataContext();

  useEffect(() => {
    if (checkInactivity()) {
      logoutUser();
    }
    updateExpTime();
    const interval = setInterval(() => {
      if (checkInactivity()) {
        logoutUser();
      }
    }, parseInt(process.env.REACT_APP_INACTIVITY_TIMECHECK_INTERVAL_SECONDS!) * 1000);

    window.addEventListener("click", updateExpTime);
    window.addEventListener("keypress", updateExpTime);
    window.addEventListener("scroll", updateExpTime);
    window.addEventListener("mousemove", updateExpTime);

    return () => {
      window.removeEventListener("click", updateExpTime);
      window.removeEventListener("keypress", updateExpTime);
      window.removeEventListener("scroll", updateExpTime);
      window.removeEventListener("mousemove", updateExpTime);
      clearInterval(interval);
    };
  }, []);

  return (
    <StyledHeader>
      <Container>
        <Nav>
          <Logo>
            Where's<span>Our?</span>
          </Logo>
          <Phrase>A better way to keep track of your things</Phrase>
          <Buttons>
            {(loggedInUserCognitoIdToken == "" ||
              loggedInUserCognitoIdToken == null) && (
              <LoginButton onClick={() => setLoginStage("login")}>
                Login
              </LoginButton>
            )}
            {loggedInUserCognitoIdToken != "" && (
              <>
                <Button onClick={logoutUser}>Logout</Button>
                {subscriptionExpired && (
                  <SubscriptionExpiredButton onClick={() => setCheckoutPopupOpen(true)}>
                    Subscription
                  </SubscriptionExpiredButton>
                )}
                {!subscriptionExpired && (
                  <Button onClick={() => setCheckoutPopupOpen(true)}>
                    Subscription
                  </Button>
                )}
              </>
            )}
          </Buttons>
        </Nav>
        <LoginFlowPopup />
      </Container>
    </StyledHeader>
  );
}
