import styled from "styled-components";

export const StyledCard = styled.div`
  display: flex;
  flex-direction:column;
  border-radius: 15px;
  padding: 10px;
  text-align:center;
  min-width: 300px;
  justify-content:space-between;
  align-items: center;
  border: solid 1px #8dabe2;
  box-shadow: 5px 5px 8px #8dabe2;
  img {
    width: auto;
    max-height: auto;
    max-width:95%;
    max-height:300px;
  };
`;

export const StyledCardHeader = styled.span`
  display: flex;
  width:100%;
  height:60px;
  align-items:center;
  justify-content:center;
  position:relative;
`;

export const LocationIcon = styled.span`
  align-self:flex-start;
  left:10px;
  top:0;
  position:absolute;
  color:#608cdf;
  `;

export const InUseIcon = styled.span`
  align-self:flex-end;
  right:10px;
  top:0;
  position:absolute;
  color:#608cdf;
  `;
