import { useUserDataContext } from "../Contexts/UserDataContext";
import { cognitoUserAttributes, useAccountContext } from "../Contexts/Account";
import {
  StyledInnerPopup_Small,
  StyledOuterPopup,
  StyledPopupButton,
} from "../styledComponents/Popup.styled";

export function SelectMoveOrUsePopup() {
  return (
    <StyledOuterPopup>
      <StyledInnerPopup_Small>
        <SelectMoveOrUse />
      </StyledInnerPopup_Small>
    </StyledOuterPopup>
  );
}

function SelectMoveOrUse() {
  const {
    setItemToMove,
    itemMap,
    itemToMoveOrUse,
    setItemToMoveOrUse,
    SaveItem,
  } = useUserDataContext();
  const { userCognitoAttrubutes } = useAccountContext();

  function OnMoveSelected() {
    setItemToMove(itemToMoveOrUse);
    setItemToMoveOrUse("");
  }

  function OnUseSelected(userId: string) {
    var itemData = itemMap[itemToMoveOrUse];
    itemData.InUse = !itemData.InUse;
    SaveItem(itemData, "", true, "", userId, false)
      .then((res) => {
        setItemToMoveOrUse("");
      })
      .catch((error) => {
        itemData.InUse = !itemData.InUse //Set in use flag back
        if (error === 0) {
          alert(
            "Error saving data. This issue can be a result of lost internet connection. Please check your connection and try again."
          );
        } else {
          alert(
            "Error saving data. Please try again and if the issue persists contact Admin@WheresOur.com. Error status = " +
              error
          );
        }
      });
  }

  return (
    <div>
      <div>
        <h2>Move Item to Another Location:</h2>
        <StyledPopupButton onClick={OnMoveSelected}>
          Click To Move Item
        </StyledPopupButton>
      </div>
      <div>
        <h2>Toggle 'InUse' status: </h2>
        <StyledPopupButton
          onClick={() =>
            OnUseSelected((userCognitoAttrubutes as cognitoUserAttributes).sub!)
          }
        >
          {itemMap[itemToMoveOrUse].InUse ? "Return Item" : "Put Item In Use"}
        </StyledPopupButton>
      </div>
      <div>
        <br />
        <StyledPopupButton onClick={() => setItemToMoveOrUse("")}>
          Cancel
        </StyledPopupButton>
      </div>
    </div>
  );
}

export default SelectMoveOrUse;
