import { createContext, useState, useEffect } from "react";
import { useUserDataContext } from "./UserDataContext";

export default function useScrollListener() {
  const { setDisplayNav, setDisplayNavLinks, displayNavLinks, displayNav } =
    useUserDataContext();
  // set up event listeners
  useEffect(() => {
    let lastScrollY = window.scrollY;
    const handleScrollNav = () => {
      if (window.scrollY > 20) {
        setDisplayNavLinks(false);
      } else if(window.scrollY < 60) {
        setDisplayNavLinks(true);
      }
      if (window.scrollY > 300 && lastScrollY < window.scrollY) {
        setDisplayNav(false);
      } else {
        setDisplayNav(true);
      }
      lastScrollY = window.scrollY;
    };

    handleScrollNav();
    window.addEventListener("scroll", handleScrollNav);

    return () => {
      window.removeEventListener("scroll", handleScrollNav);
    };
  }, []);
}
