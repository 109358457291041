import styled from "styled-components";
  
export const Container = styled.div`
width:1200px;
max-width:100%;
padding: 0px;
margin:0 auto;
`
export const CardContainer = styled.div`
max-width:100%;
display:grid;
grid-template-columns: repeat(auto-fit, minmax(300px,19%));
grid-gap:15px;
justify-content: center;
margin-top:10px;
//padding-bottom:75px;

@media (max-width: 600px) {
  grid-gap:7px;
  }
`
export const CardDataContainer = styled.div`
width:95%;
border:.5px solid black;
text-align:left;
`
export const ButtonsContainer = styled.div`
width:100%;
display:flex;
flex-wrap:wrap;
justify-content:space-evenly;
margin-top: 5px;
`

export const LoadMoreContainer = styled.div`
display:flex;
justify-content:space-around;
margin:20px;
button{
  width:300px;
  height:50px;
  background-color:#608cdf;
  color:#ffffff;
  font-size:24px;
}
@media (max-width: 600px) {
  
  }
`