import { useState } from "react";
import { useUserDataContext } from "../Contexts/UserDataContext";
import "../Styles/SelectLocation.css";
import { StyledSLContainer, StyledSLDash, StyledSLDownArrow, StyledSLUl, StyledSLUpArrow } from "../styledComponents/SelectLocation.styled";
import { StyledOuterPopup, StyledInnerPopup, StyledPopupButton } from "../styledComponents/Popup.styled";
import { Button } from "../styledComponents/Buttons.styled";

export function SelectLocationPopup() {
  return (
    <StyledOuterPopup>
      <StyledInnerPopup>
        <SelectLocation />
      </StyledInnerPopup>
    </StyledOuterPopup>
  );
}

function SelectLocation() {
  const {
    parentMap,
    itemToMove,
    setItemToMove,
    itemMap,
    moveItem,
    setSearchActive,
  } = useUserDataContext();
  const [selection, setSelection] = useState("MASTER");
  const [showChildrenArr, setShowChildrenArr] = useState<string[]>(["MASTER"]);

  function updateShowChildrenArr(itemId: string) {
    if (parentMap[itemId] != null) {
      if (showChildrenArr.includes(itemId)) {
        setShowChildrenArr((showChildrenArr) => {
          return showChildrenArr.filter((id) => id !== itemId);
        });
      } else {
        setShowChildrenArr([...showChildrenArr, itemId]);
      }
    }
  }

  function renderChildren(itemId: string) {
    return (
      <>
        {itemId != itemToMove && (
          <ul>
            {Array.from(parentMap[itemId])
              .filter((id) => id !== itemToMove)
              .map((s) => {
                return (
                  <li key={itemMap[s].ItemId}>
                    {parentMap[s] == null && (
                      <span
                        onClick={() =>
                          updateShowChildrenArr(itemMap[s].ItemId!)
                        }
                      >
                        <StyledSLDash/>
                      </span>
                    )}
                    {showChildrenArr.includes(s) && (
                      <span
                        className="expandable"
                        onClick={() =>
                          updateShowChildrenArr(itemMap[s].ItemId!)
                        }
                      >
                        <StyledSLUpArrow/>
                      </span>
                    )}
                    {parentMap[s] != null && !showChildrenArr.includes(s) && (
                      <span
                        className="expandable"
                        onClick={() =>
                          updateShowChildrenArr(itemMap[s].ItemId!)
                        }
                      >
                        <StyledSLDownArrow/>
                      </span>
                    )}
                    <span onClick={() => setSelection(itemMap[s].ItemId!)}>
                      {itemMap[s].Name == null ? "UNTITLED" : itemMap[s].Name}
                    </span>
                    {showChildrenArr.includes(s) && renderChildren(s)}
                  </li>
                );
              })}
          </ul>
        )}
      </>
    );
  }

  function move() {
    if (itemToMove == selection) {
      alert("Cannot move item into itself");
      return;
    }
    moveItem(itemToMove, selection);
    setItemToMove("");
  }

  return (
    <StyledSLContainer>
      <h2>Currently Moving "{itemMap[itemToMove].Name}"</h2>
      <StyledSLUl>
        <li onClick={() => setSelection("MASTER")}>MASTER</li>
        {renderChildren("MASTER")}
      </StyledSLUl>

      <div className="move-item-description">
        Move "{itemMap[itemToMove].Name}" to "
        {itemMap[selection] == null ? "MASTER" : itemMap[selection].Name == null ? "UNTITLED" : itemMap[selection].Name}"?
      </div>
      <StyledPopupButton onClick={move}>Confirm Move</StyledPopupButton>
      <StyledPopupButton onClick={() => setItemToMove("")}> Close</StyledPopupButton>
    </StyledSLContainer>
  );
}

export default SelectLocation;
