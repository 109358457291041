import styled from "styled-components";

export const StyledFAQUl = styled.ul`
  font-size:20px;
  ul{
    list-style: circle;
    margin-left: 10px;
    margin-bottom: 3px;
    padding: 0;
    li{
    margin-left:5px;
  }
  }

  h1{
    margin-left:0;
  }
  
`;