import styled from "styled-components";
import TagsInput from "../components/inputComponents/TagsInput";
import { Button } from "../styledComponents/Buttons.styled";

export const StyledIS_Wrapper = styled.div`
  display: flex;
  align-items: center;
  justify-content: center;
  flex-direction: column;
  padding: 2%;

  position: fixed;
  top: 0;
  left: 0;
  width: 100%;
  height: 100dvh;
  background-color: rgba(0, 0, 0, 0.2);
`;

export const StyledItemSubmissionForm = styled.form`
  width: 100%;
  display: flex;
  flex-direction: column;
  max-width: 1200px;
  border-radius: 10px;
  background-color: #8dabe2;
  padding: 10px 0px;
  overflow-y: auto;
  box-shadow: 12px 12px 2px 1px rgba(255, 255, 255, 0.2);
  z-index: 1000;
  @media (max-width: 600px) {
  }
`;

export const StyledItemSubmissionFormLabel = styled.label`
  display: flex;
  width: 90%;
  margin: 2px 5%;
  justify-content: left;
  align-items: center;
  gap: 1em;
  @media (max-width: 600px) {
    flex-direction: column;
    align-items: flex-start;
    gap: 0;
  }
`;

export const StyledItemSubmissionFormInput = styled.input`
  width: 75%;
  padding: 12px 20px;
  margin: 8px 0;
  border-radius: 4px;
  background-color: #ffffff;
  border: none;
  cursor: pointer;
  font-size: 1em;
  font-family: Arial, Helvetica, sans-serif;
  resize: vertical;
  @media (max-width: 600px) {
    width: 95%;
  }
`;

export const StyledItemSubmissionFormSpan = styled.span`
  min-width: 120px;
  word-wrap: break-word;
`;

export const StyledItemSubmissionFormTextArea = styled.textarea`
  width: 75%;
  padding: 12px 20px;
  margin: 8px 0;
  border-radius: 4px;
  background-color: #ffffff;
  border: none;
  cursor: pointer;
  font-size: 1em;
  font-family: Arial, Helvetica, sans-serif;
  resize: vertical;
  overscroll-behavior: contain;
  white-space: pre-line;
  @media (max-width: 600px) {
    width: 95%;
    min-height: 150px;
  }
`;

export const StyledItemSubmissionFormImgInput = styled(
  StyledItemSubmissionFormInput
)`
  display: none;
`;

export const StyledItemSubmissionFormImgLabel = styled(
  StyledItemSubmissionFormLabel
)`
  width: 75%;
  padding: 12px 20px;
  margin: 8px 0;
  border-radius: 4px;
  background-color: #ffffff;
  border: none;
  cursor: pointer;
  font-size: 1em;
  font-family: Arial, Helvetica, sans-serif;
  resize: vertical;
  font-size: medium;
  font-family: "Courier New", Courier, monospace;
  color: grey;
  @media (max-width: 600px) {
    width: 95%;
  }
`;

export const StyledItemSubmissionImg = styled.img`
  border-radius: 10%;
  max-width: 250px;
  max-height: 250px;
  align-self: center;
`;

export const StyledItemSubmissionImageDiv = styled.div`
  display: flex;
  position: relative;
  justify-content: center;
`;

export const StyledItemSubmissionCFContainer = styled.div`
  display: inline-block;
  width: 75%;
  @media (max-width: 600px) {
    width: 95%;
  }
`;

export const StyledItemSubmissionCFItem = styled.div`
  display: flex;
  align-items: center;
  gap: 1em;
  width: 100%;
  @media (max-width: 600px) {
    align-items: flex-start;
    gap: 0;
    border: 0.5px solid white;
  }
`;

export const StyledItemSubmissionCFData = styled.div`
  display: flex;
  align-items: center;
  gap: 1em;
  width: 100%;
  @media (max-width: 600px) {
    flex-direction: column;
    align-items: flex-start;
    gap: 0;
    margin-left: 3px;
  }
`;

export const StyledItemSubmissionCFDeleteBtnContainer = styled.div`
  display: flex;
  align-self: stretch;
  align-items: center;
  justify-items: center;

  @media (max-width: 600px) {
  }
`;

export const StyledItemSubmissionPopupMessage = styled.div`
  position: sticky;
  top: 0;
  background: #ffffff;
  color: #608cdf;
  border: 2px solid #608cdf;
  margin-bottom: 5px;
  z-index: 1;
  border-radius: 10px;
  margin-left: 3%;
  margin-right: 3%;
  padding: 1%;
  @media (max-width: 600px) {
  }
`;

export const StyledIS_ButtonContainer = styled.div`
  display: flex;
  align-items: center;
  justify-content: center;
  margin-top: 5px;
  width: 100%;
  @media (max-width: 600px) {
    flex-wrap: wrap;
    justify-content: space-evenly;
  }
`;
export const StyledISTrashButton = styled(Button)`
  width: 40px;
  height: 40px;
  @media (max-width: 600px) {

  }
`;
