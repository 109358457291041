import styled from "styled-components";

export const StyledFooterMainDiv = styled.div`
  display: flex;
  align-items: space-around;
  justify-content: space-around;
  font-size: 30px;
  background: #8dabe2;
  color:#ffffff;
  padding-bottom:10px;
  @media (max-width: 600px) {
    font-size: 16px;
  }
`;
