import { StyledInnerPopup_Small, StyledOuterPopup } from "../styledComponents/Popup.styled";

interface Props{
  message:string;
}

export function LoadingPopup(props: Props) {
  return (
    <StyledOuterPopup>
      <StyledInnerPopup_Small>
        <Loading message= {props.message} />
      </StyledInnerPopup_Small>
    </StyledOuterPopup>
  );
}

function Loading(props: Props) {
  return (
    <div>
      {props.message}
    </div>
  );
}
