import styled from "styled-components";

export const StyledHeader = styled.header`
  background-color: #8dabe2;
  padding:20px;
  @media (max-width: 600px) {
    padding:0px;
  };
`;

export const Logo = styled.div`
  font-size: 18px;

  span {
    font-size: 30px;
  }
`;

export const Nav = styled.div`
  display: flex;
  align-items: center;
  justify-content: space-between;
  margin-bottom: 15px;
  color: #ffffff;
  @media (max-width: 600px) {
    margin-bottom:0px;
    justify-content: space-around;
  };
`;

export const Phrase = styled.div`
  font-family: "Ephesis", cursive;
  font-size:2rem;
  width: 40%;
  text-align: center;
  @media (max-width: 600px) {
    display:none;
  };
`;

export const LocationPathItem = styled.span`
  &:hover{
    text-decoration: underline;
    cursor:pointer;
  }
`;
