import { Link } from "react-router-dom"
import { StyledFooterMainDiv } from "../styledComponents/Footer.styled";

export function Footer() {
  
  return (
    <StyledFooterMainDiv>
      <Link to ="/Privacy">Privacy Policy</Link>
      <Link to ="/Terms">Terms and Conditions</Link>
    </StyledFooterMainDiv>
  );
}
