import { useState } from "react";
import {
  itemDataFromDynamo,
  useUserDataContext,
} from "../Contexts/UserDataContext";
import { calculateChildren } from "../utilities/CalculateItems";

export function DeleteItemPopup() {
  return (
    <div className="login-popup">
      <div className="login-popup-inner">
        <DelItem />
      </div>
    </div>
  );
}

function DelItem() {
  const {
    itemToDelete,
    setItemToDelete,
    parentMap,
    deleteItem,
    itemMap,
    curItemData,
    searchActive,
    curItemArray,
    setCurItemArray,
    setupgradedItemFormOpen,
  } = useUserDataContext();
  const [deleteAll, setDeleteAll] = useState(false);

  async function confirmDelete(d: boolean) {
    try {
      //Send API Request to delete
      await deleteItem(itemToDelete, d);
    } catch (error) {
    } finally {
      setItemToDelete("");
      setupgradedItemFormOpen(false);
      if (searchActive) {
        setCurItemArray(
          curItemArray.filter(function (item) {
            return item.ItemId !== itemToDelete;
          })
        );
      }
    }
  }

  var itemName = (curItemData as itemDataFromDynamo).Name
    ? (curItemData as itemDataFromDynamo).Name
    : "UNTITLED";
  var parentName =
    (curItemData as itemDataFromDynamo).ParentId == "MASTER"
      ? "MASTER"
      : itemMap[(curItemData as itemDataFromDynamo).ParentId].Name
      ? itemMap[(curItemData as itemDataFromDynamo).ParentId].Name
      : "UNTITLED";
  var count = 0;

  return (
    <div>
      {
        //If item is not a parent then we don't need all the info about the item parent allocation
        parentMap[itemToDelete] == null && (
          <div>
            <h2>Confirm Delete</h2>
            <button onClick={() => setItemToDelete("")}>Close</button>
            <button onClick={() => confirmDelete(false)}>Confirm</button>
          </div>
        )
      }

      {
        //If the item is a parent then we need additional warnings and confirmations
        parentMap[itemToDelete] != null && (
          <div>
            <h2>Confirm Delete</h2>
            {!deleteAll && (
              <div>
                Confirming will delete the current location ({itemName}) and
                move all items one level below into ({parentName}).
              </div>
            )}
            <br />
            <br />
            {parentMap[itemToDelete] != null && (
              <>
                <label>Delete All? </label>
                <input
                  type="checkbox"
                  name="DeleteAll"
                  onClick={() => setDeleteAll(!deleteAll)}
                />
                {deleteAll && (
                  <>
                    <div>
                      WARNING: You are about to delete all items under this
                      item. This action is irreversable. Please move any items
                      you wish to keep tracking before proceeding.
                    </div>
                    <br />
                    The following items will be deleted in addition to '
                    {itemName}:'
                    <ul>
                      {calculateChildren(
                        parentMap,
                        (curItemData as itemDataFromDynamo).ItemId
                      ).map((item, index) => {
                        {
                          count++;
                        }
                        return (
                          <li key={index}>
                            -{" "}
                            {itemMap[item].Name
                              ? itemMap[item].Name
                              : "UNTITLED"}
                          </li>
                        );
                      })}
                    </ul>
                  </>
                )}
              </>
            )}

            <br />
            <br />
            <button onClick={() => setItemToDelete("")}>Close</button>
            <br />
            <br />
            {!deleteAll && (
              <button onClick={() => confirmDelete(false)}>Confirm</button>
            )}
            {deleteAll && (
              <button onClick={() => confirmDelete(true)}>
                Confirm Delete All
              </button>
            )}
          </div>
        )
      }
    </div>
  );
}

//export default DeleteItem;
