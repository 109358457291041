import styled from "styled-components";
import { Button } from "./Buttons.styled";

export const StyledPopupErrorMessage = styled.div`
  color: red;
`;

export const StyledPopupErrorList = styled.ul`
  color: #ff0000;
  font-weight: bold;
  background-color: #8dabff;
  border-radius: 10px;
`;

export const StyledPopupForm = styled.form`
  display: flex;
  flex-direction: column;
  input {
    border-radius: 10px;
    height: 30px;
    border-color: #608cdf;
    width: 50%;
    min-width: 250px;
    margin-bottom: 15px;
  }
`;

export const StyledOuterPopup = styled.div`
  display: flex;
  align-items: center;
  justify-content: center;
  flex-direction: column;
  padding: 2%;

  position: fixed;
  top: 0;
  left: 0;
  width: 100%;
  height: 100vh;
  background-color: rgba(0, 0, 0, 0.2);
  z-index: 1001;
  @media (max-width: 600px) {
    height: 100lvh;
  }
`;

export const StyledInnerPopup = styled.div`
  padding: 32px;
  width: 100%;
  max-width: 1200px;
  background-color:#8dabe2;
  color: #ffffff;
  border-radius: 10px;
  box-shadow: 12px 12px 2px 1px rgba(255, 255, 255, 0.2);
  font-size:20px;
  /* overflow-y: auto; */
  @media (max-width: 600px) {
    height: 100lvh;
  }
`;

export const StyledInnerPopup_Small = styled.div`
  padding: 32px;
  width: 100%;
  max-width: 600px;
  background-color:#8dabe2;
  color: #ffffff;
  border-radius: 10px;
  box-shadow: 12px 12px 2px 1px rgba(255, 255, 255, 0.2);
  font-size:20px;
  /* overflow-y: auto; */
  @media (max-width: 600px) {
    height: auto;
  }
`;

export const StyledPopupButton = styled(Button)`
  background-color: #fff;
  height: 50px;
  width:auto;
  min-width: 150px;
`;