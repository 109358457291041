import { createContext, useContext, useState } from "react";
import {
  CognitoUser,
  AuthenticationDetails,
  CognitoUserSession,
} from "amazon-cognito-identity-js";
import UserPool from "../aws/UserPool";

interface cognitoUser {
  email: string;
  password: string;
}

export interface cognitoIdentityCredentials {
  accessKeyId: string;
  secretKey: string;
  sessionToken: string;
  identityId: string;
}
export interface cognitoUserAttributes {
  sub: string | undefined;
  email_verified: string | undefined;
  email: string | undefined;
}

interface CognitoUserData {
  loggedInUserCognitoIdToken: string | undefined;
  setLoggedInUserCognitoIdToken: React.Dispatch<
    React.SetStateAction<string | undefined>
  >;

  userCognitoAttrubutes: {};
  setUserCognitoAttrubutes: React.Dispatch<React.SetStateAction<{}>>;
  authenticate: (Username: string, Password: string) => Promise<unknown>;
  getSession: () => Promise<unknown>;
  logout: () => void;
}

var sessionUserAttributes: any;
var cognitoUser: cognitoUser;

const AccountContext = createContext<CognitoUserData | null>(null);
const Account = (props: any) => {
  const [loggedInUserCognitoIdToken, setLoggedInUserCognitoIdToken] = useState<
    string | undefined
  >("");
  const [userCognitoAttrubutes, setUserCognitoAttrubutes] = useState<{}>({});
  const getSession = async () => {
    return await new Promise((resolve, reject) => {
      const user = UserPool.getCurrentUser();
      if (user) {
        user.getSession(
          (err: Error | null, session: CognitoUserSession | null) => {
            if (err) {
              reject();
            } else {
              resolve(session);
            }
          }
        );
      } else {
        reject();
      }
    });
  };

  const authenticate = async (Username: string, Password: string) => {
    return await new Promise((resolve, reject) => {
      const cognitoUser = new CognitoUser({ Username, Pool: UserPool });
      const authenticationDetails = new AuthenticationDetails({
        Username,
        Password,
      });

      cognitoUser.authenticateUser(authenticationDetails, {
        onSuccess: (result) => {
          sessionUserAttributes = cognitoUser.getUserAttributes(function (
            err,
            result
          ) {
            if (err) {
              console.log("err", err);
              return;
            }
            window.localStorage.setItem("UserCognitoAttrubutes", JSON.stringify(result))
            // window.localStorage.setItem("expireTime",Date.now() + (parseInt(process.env.REACT_APP_INACTIVITY_DELAY_SECONDS!) * 1000).toString())
            window.localStorage.setItem("Hello","Hi")
            setUserCognitoAttrubutes(result!);
          });
        },
        onFailure: (err) => {
          console.log("onFailure: ", err);
          reject(err);
        },
        newPasswordRequired: () => {
          cognitoUser.completeNewPasswordChallenge(
            process.env.REACT_APP_DEFAULT_PWD!,
            sessionUserAttributes,
            {
              onSuccess: (result) => {
                // login
              },
              onFailure: (result) => {
                // login
              },
            }
          );
        },
      });
    });
  };

  const logout = () => {
    const user = UserPool.getCurrentUser();
    setLoggedInUserCognitoIdToken("");
    if (user) {
      user.signOut();
    }
  };

  return (
    <AccountContext.Provider
      value={{
        authenticate,
        getSession,
        logout,
        userCognitoAttrubutes,
        setUserCognitoAttrubutes,
        loggedInUserCognitoIdToken,
        setLoggedInUserCognitoIdToken,
      }}
    >
      {props.children}
    </AccountContext.Provider>
  );
};

function useAccountContext() {
  const context = useContext(AccountContext);
  if (!context) {
    throw new Error("useLoginContext must be used within LoginContextProvider");
  }
  return context;
}

export { Account, useAccountContext };
