import { CheckoutForm } from "../components/CheckoutForm";

export function CheckoutPopup() {
  return (
    <div className="login-popup">
      <div className="login-popup-inner">
        <CheckoutForm />
      </div>
    </div>
  );
}
