import styled from "styled-components";
import {ArrowRight } from "react-bootstrap-icons";

export const StyledSearchContainer = styled.div`
  display: flex;
  align-items: center;
  /* background:red; */
  white-space: normal;
  word-wrap: break-word;
  width:50%;
  @media (max-width: 800px) {
    display:block;
    flex-direction: column;
    align-items:start;
  }
  @media (max-width: 600px) {
    display:flex;
    flex-direction:row;
    align-items:start;
    width:100%
  }
`;

export const StyledSearchForm = styled.form`
  display: flex;
  align-items: center;
  justify-content:end;
  /* background:blue; */
  width:70%;
  @media (max-width: 1000px) {
    width:100%;
    justify-content:start;
  }
  
  @media (max-width: 600px) {
    flex-direction: row;
    align-items:center;
    justify-content:start;
    width:auto;
  }
`;

export const StyledSearchInputContainer = styled.div`
  display: flex;
  flex-direction: column;
  align-items: end;
  /* background:pink; */
  width:70%;
  input {
    border-radius: 10px;
    height: 30px;
    border-color: #608cdf;
    width: 100%;
    min-width: 150px;
  }
  @media (max-width: 600px) {
    align-items: start;
    input{
      font-size: 16px;
      /* background:pink; */
    }
  }
`;

export const StyledSearchButtonContainer = styled.div`
  display: flex;
  @media (max-width: 600px) {
    flex-direction:column;

  }
`;

export const StyledSearchIcon = styled.form`
  display: none;
  @media (max-width: 600px) {
    display: block;
  }
`;

export const StyledSearchArrowRight = styled(ArrowRight)`
  font-size: 12px;
  margin-right: 3px;
  border-radius: 100%;
  border: none;
`;