import { StyledFAQUl } from "../styledComponents/FAQ";

export default function Home() {
  return (
    <StyledFAQUl>
      <li>
        <h1>Overview</h1>
        <ul>
          <li>
            This application was designed and built to help you better organize
            and manage the things you have around the house. The overall idea of
            the application is to allow you to create 'items' which can be
            nested inside other items. These items are then searchable which
            gives you the ability to more easily identify the location of an
            item you happen to be looking for. This application is useful for
            people moving residences, using storage units, organizing storage
            spaces in the house (attics, basements, and garage) just to name a
            few. I hope this application allows you to organize your stuff in
            such a way that you can easily find your things long after they are
            are put away.
          </li>
        </ul>
      </li>
      <li>
        <h1>Getting Started</h1>
        <ul>
          <li>
            To get started, you need login credentials. If credentials have been
            provided then please login. If you do not currently have an account,
            please check back with us as registration is currently disabled.
          </li>
        </ul>
      </li>
      <li>
        <h1>How To Guide</h1>
        <ul>
          <li>
            In this tutorial we will walk through the major functionality of the
            site.
            <div>
              <h2>Creating your first item</h2>
              <ul>
                <li>
                  Creating an item can be done in seconds. An explaination of
                  some of the application's termonology will be helpful for this
                  process so take a look at a few definitions below:
                  <ul>
                    <li>
                      Item: 'Items' are the base unit in this application and
                      denote a unit that should be tracked. Items can be
                      anything from your house to a blanket and everything in
                      between.
                    </li>
                    <li>
                      Location: A 'location' is simply an item that contains
                      other items. For example, when initially created, a box
                      will only be an 'item'. Once you add another item to the
                      box it becomes a 'location'.
                    </li>
                    <li>
                      'MASTER' Location: 'MASTER' is a special kind of location.
                      It is not an item but instead is the location for all
                      items that aren't in a location. So, when you create your
                      very first item, the location of that item will be in
                      'MASTER' by default.
                    </li>
                  </ul>
                </li>
                <br />
                <li>
                  Now that you have an understanding of the basic concepts of
                  the application, lets create our first item in a few simple
                  steps:
                  <ul>
                    <li>
                      Step 1: In the navigation bar (nav bar) at the top
                      left-hand side of the page, notice that you are currently
                      on the 'Guide' page. In order to create an item select the
                      menu item 'My Items' on the left hand side of the nav bar.
                    </li>
                    <li>
                      Step 2: In the middle of the nav bar, you should see a
                      button which says 'Add Item'. If you notice, to the left
                      of this button there is a message stating 'Current
                      Location: 'Master''. By clicking 'Add Item' you will add
                      an item to 'MASTER'. Click that button.
                    </li>
                    <li>
                      Step 3: After clicking the 'Add Item' button you should
                      have been greeted with a popup with fields such as 'Name'
                      and 'Description'. This is the screen for adding and
                      updating item data. For this tutorial, lets create an item
                      called 'House'.
                      <ul>
                        <li>
                          Step a: Enter the name 'House' in the Name field.
                        </li>
                        <li>
                          Step b: Enter the description 'This is my house.' in
                          the Description field.
                        </li>
                        <li>
                          Step c: Leave all other fields blank and click the
                          button 'Save and close'.
                        </li>
                      </ul>
                      <li>
                        Step 4: At this point, the item creation popup should
                        have disappeared and the screen that once contained no
                        cards should now have a card with a button at the top
                        stating 'House View Items'
                      </li>
                    </li>
                  </ul>
                </li>
                <br />
                <li>Congratulations, you have created your first item!</li>
                <br />
                <li>
                  Creating additional items is just as simple as creating the
                  first. There are a few notes to mention:
                </li>
                <ul>
                  <li>
                    All items require the 'Name' property to be populated OR an
                    image attached. While we recommend always attaching a name
                    to the item, there are cases where you might want to quickly
                    create unnamed items using only images and then update the
                    item data at a future time.
                  </li>
                  <li>
                    The 'Name' property for each item does not have to be
                    unique. You could have several items with the name
                    'Blanket'. If you desired to differentiate between items
                    named 'Blanket', you could do so with tags.
                  </li>
                  <li>
                    Items can contain 'Tags' which are searchable inputs
                    connected to the item. For example, you could have one or
                    more items called 'Blanket'. One way to differentiate these
                    items would be to add tags on the item during creation or on
                    edit. Perhaps each blanket belongs to a different member of
                    your family. In that case, adding a tag with their name (ex.
                    John) would allow you to search for the term 'John' and find
                    all items which contain the phrase 'John' in the name field
                    or tags.
                  </li>
                  <li>
                    Items can contain 'Custom Fields' which allows you to add
                    more data to the item. Perhaps an item has a warranty or the
                    item is a lock which has a combination. These things could
                    be entered as custom fields related to the item. Custom
                    fields are not currently searchable but it is being
                    considered as a future enhancement.
                  </li>
                </ul>
              </ul>

              <div>
                <h2>Anatomy of an item card</h2>
                <ul>
                  <li>
                    All cards are structured in a similar way regardless of
                    their status as a location. Every card contains a button at
                    the top which allows you to see what items are inside that
                    item. If none exist then the message 'This Location Does Not
                    Contain Any Items' will be displayed.
                  </li>
                  <li>
                    Cards of items that are also locations display a location
                    icon like you would see on common GPS and map applications.
                    This icon is located at the top lefthand side of the card.
                  </li>
                  <li>
                    To allow for easier item identification, items can be added
                    with an image of the item. If one is available, the image
                    will be displayed below the view item button.
                  </li>
                  <li>
                    View / Edit / Delete Item Button: Clicking this button will
                    trigger a popup similar to the one used when adding a new
                    item. This popup can be used to see the location of an item,
                    edit item data, or delete the item.
                  </li>
                  <li>
                    Move / Use Item Button: Clicking this button will trigger a
                    popup which allows the following options:
                    <ul>
                      <li>
                        Move Item to Another Location:
                        <ul>
                          <li>
                            When the 'Click To Move Item' button is clicked, a
                            list of items directly under 'MASTER' will appear
                            and the message at the bottom will indicate that the
                            item is set to move to 'Master'. For more
                            information on this functionality, see the 'Moving
                            an Item' section of this guide.
                          </li>
                        </ul>
                      </li>
                      <li>
                        Toggle 'InUse' status:
                        <ul>
                          <li>
                            If the item is not currently set to 'InUse' there
                            will be a button labled 'Put Item In Use'. If the
                            item is already being used then the button will be
                            labeled 'Return Item'. For more information on this
                            functionality, see the 'Using an Item' section of
                            this guide.
                          </li>
                        </ul>
                      </li>
                    </ul>
                  </li>
                </ul>
              </div>
              <div>
                <h2>Moving an Item</h2>
                <ul>
                  <li>
                    As mentioned before, the 'Click To Move Item' button on the
                    card will open a popup allowing you to move an item.
                  </li>
                  <li>
                    Items that contain other items will have a down arrow
                    indicator on the left which once clicked will open a list of
                    items inside that item. When the indicator is set to up
                    arrow it means the location is displaying the items it
                    contains. When the indicator is a dash it means the item is
                    not a locaiton. Keep in mind that just because an item is
                    not currently a location does not mean it can't become one.
                    As such all items can be moved into any other item. There is
                    one exception to this, items cannot be moved into themselves
                    ('Box 1' can't be added inside 'Box 1') or any items where
                    the item being moved is part of the location path ('Box 1'
                    can't be added to 'Box 3' if the path is 'Box 1/Box 2/Box
                    3').
                  </li>
                </ul>
              </div>
              <div>
                <h2>Using an Item</h2>
                <ul>
                  <li>
                    As mentioned before, the 'Put Item In Use'/'Return Item'
                    buttons are used to toggle the 'InUse' status of the item.
                  </li>
                  <li>
                    Items are intended to be set to 'InUse' when the physical
                    item is being moved from it's storage location but you
                    intend to return the item at a future date. For example,
                    perhaps you are going on vacation and want to bring a cooler
                    with you. In that case, you would find the cooler you wish
                    to take and set it to 'InUse'.
                  </li>
                  <li>
                    Setting an item to 'InUse' is helpful because it allows you
                    to utilize the 'In Use?' button in the nav bar. Clicking
                    this button will display a list of all the items that are
                    currently in use. In our vacation example where a cooler was
                    set to 'InUse', clicking the 'In Use?' button in the nav bar
                    will display the card for the cooler. That card can then be
                    used to determine the location where the cooler should be
                    returned.
                  </li>
                </ul>
              </div>
              <div>
                <h2>Deleting an item</h2>
                <ul>
                  <li>
                    Deleting an item will remove the item from your set of items
                    and is an irreversable action.
                  </li>
                  <li>
                    When deleting an item that does not contain other items a
                    popup will appear after clicking delete to confirm deletion.
                    When confirm is selected the item will be deleted.
                  </li>
                  <li>
                    Deleting items that contain other items is a bit more
                    complicated. There are two options in this scenario, delete
                    all and delete item but move items inside the location one
                    level up.
                  </li>
                  <ul>
                    <li>
                      To delete all items contained in the item being deleted,
                      select the checkbox 'Delete All?' in the popup. Once
                      checked, you should see a warning about the items to be
                      deleted as well as a list of all the item names being
                      deleted. If there are items you don't want deleted in that
                      list, click close and move the items you wish to keep so
                      they are no longer contained in the item you wish to
                      delete.
                      <ul>
                        <li>
                          Pro Tip: Since items can be moved with the 'Move Item'
                          functionality mentioned above, if an item you wish to
                          delete contains a location you don't want to delete,
                          simply move that item to a different location prior to
                          deletion.
                        </li>
                      </ul>
                    </li>
                    <li>
                      To delete only the location and not all the item's it
                      contains, ensure that the 'Delete All?' checkbox is
                      unchecked. In this situation, the items contained in the
                      item being deleted will be moved into the item containing
                      the deleted item. For example if 'Box 1' contains 'Box 2'
                      and 'Box 2' contains three items (A, B and C). Then
                      deleting the location 'Box 2' will move A, B and C into
                      'Box 1'. If A, B, and/or C contained other items, these
                      items will not be impacted and can still be found in the
                      same location where they were before the deletion.
                      <ul>
                        <li>
                          Pro Tip: If a location is damaged, perhaps a cardboard
                          box came into contact with water, create a new
                          location in the application, move the box to be
                          deleted into the location just created, and then
                          delete the old box. That will prevent you from having
                          to move all the items from the box being deleted into
                          a different location.
                        </li>
                      </ul>
                    </li>
                  </ul>
                </ul>
              </div>
              <div>
                <h2>Item Search</h2>
                <ul>
                  <li>
                    Creating items and labeling them with meaningful tags is
                    critical to the main purpose of this application... Finding
                    the item after things have been 'Organized'. How many times
                    have you put something somewhere and said 'I will put this
                    here so I remember' and then forget a short while later?
                    Taking just a few moments to tag an item will help you
                    quickly and easily find that item in the future.
                  </li>
                  <li>Searching:</li>
                  <ul>
                    <li>
                      There are two search boxes which allow for creating quick
                      results. One is the 'Location Search' input and the other
                      is the 'Item Search' input. Each input allows multiple
                      search values when seperated with a semicolon(';'). When
                      using multiple search values, items matching any one of
                      the values will be returned in the search. An item does
                      NOT have to match all search values.
                    </li>
                    <li>
                      Providing a search parameter in only the 'Location Search'
                      input will return all items that contain other items
                      (definition of a location) and contain the search
                      parameter in either the Name field or as one of the Tag
                      inputs. So, a search for 'Closet' will return items such
                      as 'Bedroom Closet' and 'Hall Closet' but would also
                      return 'Suitcase' if there was a tag on the suitcase
                      indicating the item is a 'Closet Suitcase'. Keep in mind
                      that only locations are returned in this type of search so
                      'Master Closet' will not be returned if the item is empty.
                    </li>
                    <li>
                      Providing a search parameter in only the 'Item Search'
                      input will return all items that contain the search
                      parameter in either the Name field or as one of the Tag
                      inputs. The results will contain any item with a match,
                      including locations.
                    </li>
                    <ul>
                      <li>
                        The 'Item Search' input allows the following special
                        search values that can be combined with a 'Location
                        Search' value. Keep in mind that the 'Location Search'
                        includes a search on the loaction's name as well as
                        tags. So if a locaiton search returns more than just the
                        location you want to seach, you might need to update
                        the location search value in order to search only the
                        location you are interested in. The special search
                        values are:
                      </li>
                      <ul>
                        <li>
                          Searching 'L*' will return items that are also
                          locations. This search will include locations nested
                          in other locations so if the location being searched
                          was 'Box 1' and that location contained another
                          location called 'Box 2' which contained the item
                          'Blanket' then this special search would return only
                          'Box 2'.
                        </li>
                        <li>
                          Searching 'I*' will return items that are NOT also
                          locations. This search will include all non-location
                          items including items nested inside other locations.
                          So if the location being search was 'Box 1' which
                          contained 'Box 2' which contained the item 'Blanket',
                          a search value of 'I*' would return 'Blanket'
                        </li>
                        <li>
                          Searching '*' will return all items. This search will
                          include all items nested inside other locations. So if
                          the location being search was 'Box 1' which contained
                          'Box 2' which contained the item 'Blanket', a search
                          value of '*' would return 'Box 2' AND 'Blanket'
                        </li>
                      </ul>
                    </ul>
                    <li>
                      Providing a search parameter in both the 'Location Search'
                      and 'Item Search' inputs will return all items that
                      contain the search parameter from the 'item search' and
                      are in a location that contains the 'Location search'
                      search parameter. The results will contain any item with a
                      match, including locations.
                    </li>
                  </ul>
                </ul>
              </div>
            </div>
          </li>
        </ul>
      </li>
    </StyledFAQUl>
  );
}
