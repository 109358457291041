import "../Styles/LoginPopup.css";
import { useLoginStageContext } from "../Contexts/LoginStageContext";
import React, { SetStateAction, useState } from "react";
import UserPool from "../aws/UserPool";
import { CognitoUser } from "amazon-cognito-identity-js";
import { StyledPopupErrorList, StyledPopupForm } from "../styledComponents/Popup.styled";
import {Button} from "../styledComponents/Buttons.styled";

function ForgotPasswordPopup() {
  const { setLoginStage } = useLoginStageContext();
  return (
    <div className="login-popup">
      <div className="login-popup-inner">
        {/* <button className="close-btn" onClick={() => setLoginStage("none")}>
          Close
        </button> */}
        <ForgorPassword />
      </div>
    </div>
  );
}

function ForgorPassword() {
  const [email, setEmail] = useState("");
  const [stage, setStage] = useState<1 | 2>(1);

  const [code, setCode] = useState("");
  const [password, setPassword] = useState("");
  const [confirmPassword, setConfirmPassword] = useState("");
  const { setLoginStage, setLoginMessage } = useLoginStageContext();
  const [errorMessage, setErrorMessage] = useState("");

  const getUser = () => {
    return new CognitoUser({
      Username: email.toLocaleLowerCase(),
      Pool: UserPool,
    });
  };

  const sendCode = (event: React.FormEvent<HTMLFormElement>) => {
    event.preventDefault();

    getUser().forgotPassword({
      onSuccess: (data) => {setErrorMessage("")},
      onFailure: (err) => {setErrorMessage("ERROR: Unable to send varification code. Please check the email address.")},
      inputVerificationCode: (data) => {
        setStage(2);
      },
    });
  };

  const resetPassword = (event: React.FormEvent<HTMLFormElement>) => {
    event.preventDefault();

    if (password !== confirmPassword) {
      setErrorMessage("Passwords must match");
      return;
    }

    getUser().confirmPassword(code, password, {
      onSuccess: (data) => {
        setLoginMessage("Password Successfully Reset");
        setLoginStage("login");
      },
      onFailure: (err) => {
        if (err.name == "InvalidParameterException") {
          setErrorMessage("Password issue");
        } else {
          setErrorMessage("Issue Setting Password");
        }
      },
    });
  };

  function changeStage(value:SetStateAction<1|2>){
    setErrorMessage("")
    setStage(value);
  }

  return (
    <>
      {stage === 1 && (
        <StyledPopupForm onSubmit={sendCode}>
          <label>Email: </label>
          <input
            value={email}
            onChange={(event) => setEmail(event.target.value)}
          />
          {errorMessage != "" && <StyledPopupErrorList><li>ERROR: {errorMessage}</li></StyledPopupErrorList>}
          <div>
            <Button type="submit">Send Varification Code</Button>
            <Button type="button" onClick={() => changeStage(2)}>
              Already have Code
            </Button>
          </div>
        </StyledPopupForm>
      )}

      {stage === 2 && (
        <StyledPopupForm onSubmit={resetPassword}>
          <label>Code</label>
          <input
            value={code}
            onChange={(event) => setCode(event.target.value)}
          />
          <label>Password</label>
          <input
            type="password"
            value={password}
            onChange={(event) => setPassword(event.target.value)}
          />
          <label>Confirm Password</label>
          <input
            type="password"
            value={confirmPassword}
            onChange={(event) => setConfirmPassword(event.target.value)}
          />
          {email != "" && (
            <div>
              An email was sent to <b>{email}</b> with a verification code.
            </div>
          )}
          {errorMessage != "" && <StyledPopupErrorList><li>ERROR: {errorMessage}</li></StyledPopupErrorList>}
          <div>
            <Button type="submit">Reset Password</Button>
            <Button type="button" onClick={() => changeStage(1)}>
              Send New Code
            </Button>
          </div>
        </StyledPopupForm>
      )}
      <br />
      <Button type="button" onClick={() => setLoginStage("login")}>
        Back to Login
      </Button>
    </>
  );
}

export default ForgotPasswordPopup;
