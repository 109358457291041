import styled from "styled-components";
import {
  ChevronDown,
  ChevronUp,
  Dash,
} from "react-bootstrap-icons";

export const StyledSLContainer = styled.div`
  overflow-y:auto;
  max-height:75vh;
  overscroll-behavior:none;
`;

export const StyledSLUl = styled.ul`
  ul,
  li {
    list-style-type: none;
    list-style-position: inside;
    margin-left: 5px;
    margin-bottom: 3px;
    padding: 0;
  }
`;

export const StyledSLDownArrow = styled(ChevronDown)`
  font-size: 12px;
  margin-right: 3px;
  border-radius: 100%;
  border: none;
`;

export const StyledSLUpArrow = styled(ChevronUp)`
  font-size: 12px;
  margin-right: 3px;
  border-radius: 100%;
  border: none;
`;

export const StyledSLDash = styled(Dash)`
  font-size: 12px;
  margin-right: 3px;
  border-radius: 100%;
  border: none;
`;
