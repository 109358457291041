import {
  useUserDataContext,
  itemDataFromDynamo,
} from "../Contexts/UserDataContext";
import defaultImg from "../Shared/Images/No_Image_Icon.jpg";
import { DeleteItemPopup } from "../Popups/DeleteItem";
import { SelectLocationPopup } from "../Popups/SelectLocation";
import { ItemFormUpgradePopup } from "../components/inputComponents/ItemFormUpgrade";
import {
  InUseIcon,
  LocationIcon,
  StyledCard,
  StyledCardHeader,
} from "../styledComponents/Cards.styled";
import {
  Button,
  CardButton,
  CardButtonTitle,
  LongerCardButton,
} from "../styledComponents/Buttons.styled";
import {
  ButtonsContainer,
  CardContainer,
  LoadMoreContainer,
} from "../styledComponents/Containers.styled";
import { GeoAlt, BagCheck } from "react-bootstrap-icons";
import { SelectMoveOrUsePopup } from "../Popups/SelectMoveOrUse";
import { LoadingPopup } from "../Popups/Loading";
import { useEffect, useState } from "react";
import { checkInactivity } from "../utilities/Inactivity";
import { CheckoutPopup } from "../Popups/CheckoutPopup";

let httpMethod: string = "";
let currentItemId: string = "";

export default function MyItems(props: {alrt: string|null}) {
  //const [itemsInView, setItemsInView] = useState(10);
  const {
    curItemArray,
    itemMap,
    parentMap,
    setParentToBe,
    setCurItemData,
    itemToDelete,
    itemToMove,
    itemToMoveOrUse,
    setItemToMoveOrUse,
    upgradedItemFormOpen,
    setupgradedItemFormOpen,
    searchActive,
    recalculateCurrentArray,
    CancelSearch,
    isLoading,
    logoutUser,
    itemsInView,
    setItemsInView,
    checkoutPopupOpen,
    subscriptionExpired,
  } = useUserDataContext();

  function editItem(item: itemDataFromDynamo) {
    httpMethod = "PUT";
    currentItemId = item.ItemId;
    setParentToBe(item.ParentId);
    setCurItemData(item);
    setupgradedItemFormOpen(true);
  }

  function changeView(itemId: string, item: itemDataFromDynamo) {
    CancelSearch();
    recalculateCurrentArray(parentMap, itemId, itemMap, false);
  }

  useEffect(() => {
    if (checkInactivity()) {
      logoutUser();
    } else if(props.alrt != null){
      if(props.alrt == "success"){
        alert("Thank you for your subscription!")
      }
    }
  }, []);

  return (
    <>
      {/* <div>
        <button onClick={() => console.log("Current Parent = ", curParent)}>
          Current Parent
        </button>
        <button onClick={() => console.log("CurrItemArray = ", curItemArray)}>
          Current Item Array
        </button>
        <button onClick={() => console.log("SearchActive = ", searchActive)}>
          Is Search Active
        </button>
        <button onClick={() => console.log("SearchMap = ", searchMap)}>
          SearchMap
        </button>
      </div> */}
      <div>
        {upgradedItemFormOpen && (
          <ItemFormUpgradePopup
            httpMethod={httpMethod}
            itemId={currentItemId}
          />
        )}
        {itemToDelete != "" && <DeleteItemPopup />}
        {itemToMove != "" && <SelectLocationPopup />}
        {itemToMoveOrUse != "" && <SelectMoveOrUsePopup />}
        {isLoading && <LoadingPopup message="Loading..." />}
        {checkoutPopupOpen && <CheckoutPopup />}
        <div>
          <CardContainer>
            {curItemArray.length > 0 &&
              curItemArray.slice(0, itemsInView).map((item, index) => (
                <StyledCard key={index}>
                  <StyledCardHeader>
                    {parentMap[item.ItemId] != null && (
                      <LocationIcon>
                        <h2>
                          <GeoAlt />
                        </h2>
                      </LocationIcon>
                    )}
                    <CardButtonTitle
                      onClick={() => changeView(item.ItemId!, item)}
                    >
                      {item?.Name != null && item.Name.trim() != ""
                        ? item.Name
                        : "UNTITLED"}
                      <br />
                      View Items
                    </CardButtonTitle>
                    {item.InUse && (
                      <InUseIcon>
                        <h2>
                          <BagCheck />
                        </h2>
                      </InUseIcon>
                    )}
                  </StyledCardHeader>
                  <img
                    src={
                      item != null && item.Image != ""
                        ? item.Image + "_mainimg.jpeg"
                        : defaultImg
                    }
                    onError={(o) => {
                      var img = defaultImg;
                      if (o.currentTarget.src != img) {
                        o.currentTarget.src = img;
                      }
                    }}
                  />
                  <ButtonsContainer>
                    <CardButton onClick={() => editItem(item)}>
                      View / Edit / Delete Item
                    </CardButton>
                    <CardButton
                      onMouseUp={() => setItemToMoveOrUse(item.ItemId!)}
                    >
                      Move / Use Item
                    </CardButton>
                    {searchActive && (
                      <LongerCardButton
                        onClick={() => {
                          changeView(
                            item.ParentId != null &&
                              itemMap[item.ParentId] != null
                              ? itemMap[item.ParentId].ItemId!
                              : "MASTER",
                            item
                          );
                        }}
                      >
                        View Items in{" "}
                        {item != null &&
                        item.ParentId != null &&
                        itemMap[item.ParentId] != null &&
                        itemMap[item.ParentId].Name != null
                          ? itemMap[item.ParentId].Name
                          : item != null &&
                            item.ParentId != null &&
                            item.ParentId == "MASTER"
                          ? "MASTER"
                          : "Name Not Provided"}
                      </LongerCardButton>
                    )}
                  </ButtonsContainer>
                </StyledCard>
              ))}
            {curItemArray.length == 0 &&
              Object.keys(itemMap).length > 0 &&
              (searchActive ? (
                <div>No Search Results</div>
              ) : (
                <div>This Location Does Not Contain Any Items</div>
              ))}
            {localStorage.getItem("CognitoIdToken") != null &&
              curItemArray.length == 0 &&
              Object.keys(itemMap).length === 0 &&
              !subscriptionExpired && (
                <div>This account currently contains zero items.</div>
              )}
            {localStorage.getItem("CognitoIdToken") != null &&
              curItemArray.length == 0 &&
              Object.keys(itemMap).length === 0 &&
              subscriptionExpired && (
                <div>Could not retrieve items since the subscription is expired.</div>
              )}
            {localStorage.getItem("CognitoIdToken") == null && (
              <div>You are not logged in</div>
            )}
          </CardContainer>
          <LoadMoreContainer>
            {curItemArray.length > itemsInView && (
              <Button
                onClick={() =>
                  setItemsInView(
                    itemsInView +
                      parseInt(process.env.REACT_APP_ITEM_VIEW_INTERVAL!)
                  )
                }
              >
                Load More
              </Button>
            )}
          </LoadMoreContainer>
        </div>
      </div>
    </>
  );
}
