export function checkInactivity():boolean {
  const expireTime = localStorage.getItem("expireTime");
  const sessionExpireTime = localStorage.getItem("sessionExpireTime");

  if (expireTime != null && sessionExpireTime != null) {
    const expTimeInt: number = parseInt(localStorage.getItem("expireTime")!);
    const sessionExpireTime: number = parseInt(localStorage.getItem("sessionExpireTime")!);
    
    if (expTimeInt < Date.now()) {
      alert("You have been logged out due to inactivity.")
      return true;
    }
    if (sessionExpireTime < Date.now()) {
      alert("You have been logged since the session has exceeded the 24 hour limit.")
      return true;
    }
    return false
  }
  return false;
}

export function updateExpTime(){
    if(localStorage.getItem("CognitoIdToken") != null){
        const expireTime = Date.now() + parseInt(process.env.REACT_APP_INACTIVITY_DELAY_SECONDS!) * 1000;
        localStorage.setItem("expireTime",expireTime.toString())
    }
}
