import "../Styles/LoginPopup.css";
import { useLoginStageContext } from "../Contexts/LoginStageContext";
import { useState } from "react";
import {
  CognitoUser,
  AuthenticationDetails,
  CognitoUserSession,
} from "amazon-cognito-identity-js";
import { useFormik } from "formik";
import { useNavigate } from "react-router-dom";
import UserPool from "../aws/UserPool";
import AWS from "aws-sdk";
import {
  cognitoIdentityCredentials,
  useAccountContext,
} from "../Contexts/Account";
import { itemDataFromDynamo } from "../Contexts/UserDataContext";
import { StyledPopupErrorMessage } from "../styledComponents/Popup.styled";

//function for displaying the login content as a popup
function LoginPopup() {
  const { setLoginStage } = useLoginStageContext();
  return (
    <div className="login-popup">
      <div className="login-popup-inner">
        {/* <button className="close-btn" onClick={() => setLoginStage("none")}>
          Close
        </button> */}
        <Login />
      </div>
    </div>
  );
}

interface cognitoUser {
  email: string;
  password: string;
}

//interface for managing login error state
interface loginFailureProps {
  loginFailed: boolean;
  loginErrMessage: string;
}

var sessionUserAttributes: any;
var cognitoUser: cognitoUser;

const Login = () => {
  const navigate = useNavigate();
  const {
    setLoggedInUserCognitoIdToken,
    setUserCognitoAttrubutes,
    loggedInUserCognitoIdToken,
  } = useAccountContext();
  const [loginErrProps, setLoginFailed] = useState<loginFailureProps>({
    loginFailed: false,
    loginErrMessage: "",
  });
  const { setLoginStage, loginMessage } = useLoginStageContext();
  const [userItemInfo, setUserItemInfo] = useState<{
    itemHashMap: Record<string, itemDataFromDynamo>;
    searchHashMap: Record<string, Set<string>>;
    parentHashMap: Record<string, Set<string>>;
  } | void>();

  const handleSubmit = async (e: any, values: cognitoUser) => {
    e.preventDefault();

    const cognitoUser = new CognitoUser({
      Username: values.email,
      Pool: UserPool,
    });

    const authenticationDetails = new AuthenticationDetails({
      Username: values.email,
      Password: values.password,
    });

    cognitoUser.authenticateUser(authenticationDetails, {
      onSuccess: (r: CognitoUserSession) => {
        sessionUserAttributes = cognitoUser.getUserAttributes(function (
          err,
          result
        ) {
          if (err) {
            console.log("err", err);
            return;
          }

          //On Login, set the idtoken for the user
          setLoggedInUserCognitoIdToken(r.getIdToken().getJwtToken());

          const obj = result?.reduce(
            (obj, item) => ({
              ...obj,
              [item.Name]: item.Value,
            }),
            {}
          );
          window.localStorage.setItem(
            "UserCognitoAttrubutes",
            JSON.stringify(obj)
          );
          window.localStorage.setItem(
            "CognitoIdToken",
            r.getIdToken().getJwtToken()
          );
          window.localStorage.setItem(
            "sessionExpireTime",
            (
              Date.now() +
              parseInt(process.env.REACT_APP_MAX_SESSION_SECONDS!) * 1000
            ).toString()
          );
          setUserCognitoAttrubutes(obj!);

          setLoginStage("none");
          navigate("/MyItems");
        });
      },
      onFailure: (err) => {
        console.log("login failed", err);
        setLoginFailed({ loginFailed: true, loginErrMessage: err.message });
      },
      newPasswordRequired: () => {
        cognitoUser.completeNewPasswordChallenge(
          process.env.REACT_APP_DEFAULT_PWD!,
          sessionUserAttributes,
          {
            onSuccess: (result) => {
              // login
            },
            onFailure: (result) => {
              // login
            },
          }
        );
      },
    });
  };

  const formik = useFormik({
    initialValues: {
      email: "",
      password: "",
    },
    onSubmit: (values: cognitoUser) => {
      //handleSubmit(values);
    },
  });

  return (
    <>
      <div>
        <form onSubmit={() => formik.handleSubmit}>
          <h1>Sign In</h1>

          <div className="login__fieldName" id="email" data-lpignore="true">
            Email Address
          </div>
          <input
            className="login__inputField"
            name="email"
            type="email"
            onChange={formik.handleChange}
            onBlur={formik.handleBlur}
            value={formik.values.email}
            data-lpignore="true"
            autoComplete="off"
          />
          {formik.touched.email && formik.errors.email ? (
            <div className="login__error">{formik.errors.email}</div>
          ) : null}

          <div className="login__fieldName" id="password">
            Password
          </div>
          <input
            className="login__inputField"
            name="password"
            type="password"
            onChange={formik.handleChange}
            onBlur={formik.handleBlur}
            value={formik.values.password}
            data-lpignore="true"
            autoComplete="off"
          />
          {formik.touched.password && formik.errors.password ? (
            <div className="login__error">{formik.errors.password}</div>
          ) : null}

          {loginMessage != null && (
            <>
              <div>{loginMessage}</div> <br />
            </>
          )}

          <div>
            <button
              className="login__submitButton"
              onClick={(e) => handleSubmit(e, formik.values)}
            >
              Log in
            </button>
            <button className="login__submitButton" onClick={() => setLoginStage("none")}>
              Cancel
            </button>
            {loginErrProps.loginFailed && (
              <StyledPopupErrorMessage>
                {loginErrProps.loginErrMessage}
              </StyledPopupErrorMessage>
            )}
            <br />
            <br />
            <span>
              <button
                onClick={function (event) {
                  setLoginStage("register");
                }}
              >
                Register
              </button>
              <button
                onClick={function (event) {
                  setLoginStage("forgot_password");
                }}
              >
                Forgot Password
              </button>
            </span>
          </div>
        </form>
      </div>
    </>
  );
};

export default LoginPopup;
