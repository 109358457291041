import React, { useState } from "react";
import "../Styles/LoginPopup.css";

import { useLoginStageContext } from "../Contexts/LoginStageContext";
import { CognitoUserPool } from "amazon-cognito-identity-js";
import {
  StyledPopupErrorList,
  StyledPopupForm,
} from "../styledComponents/Popup.styled";
import { Link } from "react-router-dom";

function SignupPopup() {
  const { setLoginStage } = useLoginStageContext();
  return (
    <div className="login-popup">
      <div className="login-popup-inner">
        {/* <button className="close-btn" onClick={() => setLoginStage("none")}>
          Close
        </button> */}
        <Signup />
      </div>
    </div>
  );
}

const Signup = () => {
  const [errors, setErrors] = useState<string[]>([]);
  const [email, setEmail] = useState("");
  const [password, setPassword] = useState("");
  const [confirmPassword, setConfirmPassword] = useState("");
  //const {setLoginPopupTrigger, setSignupPopupTrigger} = useLoginContext();
  const { setLoginStage, setLoginMessage } = useLoginStageContext();

  const onSubmit = async (event: React.FormEvent<HTMLFormElement>) => {
    event?.preventDefault();
    setErrors([]);
    await timeout(200);

    const userPool = new CognitoUserPool({
      UserPoolId: process.env.REACT_APP_USERPOOL_ID!, //Needed '!' in order to pass "type 'string | undefined' is not assignable to type 'string'." error
      ClientId: process.env.REACT_APP_APPCLIENT_ID!,
    });

    if (password === confirmPassword) {
      userPool.signUp(email, password, [], [], (err, data) => {
        if (err) {
          setErrors([err.name]);
        } else {
          setErrors([]);

          setLoginMessage(
            "An email was sent to '" +
              data!.user.getUsername() +
              "'. Please confirm the email address using the link in the email."
          );
          setLoginStage("login");
        }
      });
    } else {
      setErrors(["PasswordsMustMatch"]);
    }
  };

  function timeout(ms: number) {
    return new Promise((resolve) => setTimeout(resolve, ms));
  }
  function renderSwitch(param: string) {
    switch (param) {
      case "InvalidParameterException":
      case "InvalidPasswordException":
        return (
          <>
            <li>ERROR:</li>
            <li>Valid email address is required</li>
            <li>
              Password is required to have the following:
              <ul>
                <li>Minimum 8 characters</li>
                <li>At least one uppercase character</li>
                <li>At least one lowercase character</li>
                <li>At least one number</li>
                <li>At least one symbol</li>
              </ul>
            </li>
          </>
        );
      case "PasswordsMustMatch":
        return <li>ERROR: Password and Confirm Password must match</li>;
      case "NotAuthorizedException":
        return <li>ERROR: Registration is currently disabled</li>;

      default:
        return (
          <li>
            ERROR: Registration failed for an unknown reason. Contact{" "}
            <a href="mailto:admin@wheresour.com">admin@wheresour.com</a> to
            resolve the issue.
          </li>
        );
    }
  }

  return (
    <div>
      <h1>Register</h1>
      <StyledPopupForm onSubmit={onSubmit}>
        <label htmlFor="email">Email</label>
        <input
          value={email}
          onChange={(event) => setEmail(event.target.value)}
        />
        <label htmlFor="password">Password</label>
        <input
          type="password"
          value={password}
          onChange={(event) => setPassword(event.target.value)}
        />
        <label htmlFor="password">Confirm Password</label>
        <input
          type="password"
          value={confirmPassword}
          onChange={(event) => setConfirmPassword(event.target.value)}
        />
        <br />
        {errors.length > 0 && (
          <StyledPopupErrorList>
            {renderSwitch(errors.at(0)!)}
          </StyledPopupErrorList>
        )}
        <div>
          <div>
            By registering an account you agree with WheresOur's{" "}
            <Link to="/Terms" onClick={() => setLoginStage("none")}>
              Terms and Conditions
            </Link>{" "}
            and{" "}
            <Link to="/Privacy" onClick={() => setLoginStage("none")}>
              Privacy Policy
            </Link>
          </div>
          <br />
          <button type="submit">Signup</button>
          <button type="button" onClick={() => setLoginStage("login")}>
            Back to Login
          </button>
        </div>
      </StyledPopupForm>
    </div>
  );
};

export default SignupPopup;
