import styled from "styled-components";

export const StyledHomeMainDiv = styled.div`
  background: #8dabe2;
  border: 1px solid #8dabe2;
`;

export const StyledInfoDiv = styled.div`
  color: #ffffff;
  background: #608cdf;
  font-size: 18px;
  font-weight: 500;
  text-align: center;
  padding: 5px 1% 10px 1%;
  margin: 15px 5%;
  border-radius: 25px;
  h1 {
    margin: 0;
  }
  @media (max-width: 600px) {
    h1 {
      font-size: 20px;
    }
    p {
      font-size: 14px;
      font-weight: 400;
    }
  }
`;

export const StyledGrid = styled.div`
  display: grid;
  background: #8dabe2;
  gap: 1rem;
  grid-template-columns: repeat(auto-fit, minmax(550px, 1fr));
  margin: 10px 0px;
  padding: 0px 10%;
  div {
    display: flex;
    position: relative;
    align-items: center;
    justify-content: center;
    opacity: 1;
    &:hover,
    :focus {
        background:#608cdf;
        box-shadow: none;
      p {
        display: block;
      }
      h3 {
        background:#608cdf;
      }
      img {
        opacity: 0.00;
        background-color:#608cdf;
      }
    }
  }
  @media (max-width: 600px) {
    padding: 0 2%;
    grid-template-columns: 1fr;
    div {
      &:hover,
      :focus {
        div {
          font-size: 12px;
        }
      }
    }
  }
`;

export const StyledImg = styled.img`
  height: 100%;
  width: 100%;
  border-radius: 0px;
  @media (max-width: 600px) {
    min-height: 250px;
  }
`;

export const StyledGridCell = styled.div`
  display: flex;
  align-items: center;
  position: relative;
  color: #ffffff;
  box-shadow: 15px 15px 10px #608cdf;
  h3 {
    height: 10%;
    width: 100%;
    background: #8dabe2;
    opacity: 85%;
    text-align: center;
    justify-content: space-around;
    margin: 0;
    position: absolute;
    top: 0;
  }
  p {
    position: absolute;
    display: none;
    font-size: 18px;
    margin:0px 5%;
  }
  @media (max-width: 600px) {
    h3 {
      font-size: 14px;
    }
    p {
      font-size: 14px;
      font-weight:500;
    }
  }
`;
