import { useState } from "react";
import { itemDataFromDynamo } from "../Contexts/UserDataContext";

export function calculateParentPath(
  itemMap: Record<string, itemDataFromDynamo>,
  curParent: string
): string[] {
  if (Object.keys(itemMap).length > 0) {
    let parentPath: string[] = [];
    while (curParent != "MASTER") {
      parentPath.push(curParent);
      curParent = itemMap[curParent].ParentId;
    }
    parentPath.push("MASTER");
    parentPath = parentPath.reverse();
    return parentPath;
  }
  return [];
}

export function calculateChildren(
  parentMap: Record<string, Set<string>>,
  curParent: string,
  allChildren: string[] = []
): string[] {
  if (Object.keys(parentMap).length > 0) {
    let curChildren = parentMap[curParent];
    if (curChildren != null) {
      curChildren.forEach((x: string) => {
        allChildren.push(x);
        calculateChildren(parentMap, x, allChildren).forEach((element) => {});
      });
    }
    return allChildren;
  }
  return [];
}
