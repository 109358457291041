import { Link } from "react-router-dom";
import styled from "styled-components";

interface Props {
  displaynav: string;
}
interface NavLinksProps {
  displaynavlinks: string;
}

export const StyledHeaderNav = styled.nav<Props>`
  background-color: #608cdf;
  color: white;
  display: flex;
  ${(props) =>
      props.displaynav.toLowerCase() == "notsticky"
        ? "position: static"
        : "position: sticky"};
  gap: 0px;
  top: 5px;
  z-index: 999;
  overflow: hidden;
  @media (max-width: 600px) {
    flex-direction: column;
    transform: ${(props) =>
      props.displaynav.toLowerCase() == "true" || props.displaynav.toLowerCase() == "notsticky"
        ? "translate(0,0px)"
        : "translate(0,-200px)"};
    transition: all 1s ease-out;
  }
`;

export const StyledHeaderLoggedIn = styled.div`
  /* background-color: black; */
  width: 100%;
  flex-direction: row;
  color: white;
  display: flex;
  position: sticky;
  gap: 0px;
  top: 5px;
  z-index: 999;
  overflow: hidden;
  @media (max-width: 600px) {
    flex-direction: column;
    transition: all 1s ease-out;
    height: auto;
  }
`;

export const StyledHeaderNavLoc = styled.div`
  width: 60%;
  @media (max-width: 600px) {
    width: 100%;
  }
`;

export const StyledHeaderNonNav = styled.div`
  display: flex;
  flex-direction: row;
  @media (max-width: 600px) {
    max-width: 100%;
    justify-content: space-between;
  }
`;

export const StyledHeaderNavLocPath = styled.div`
  @media (max-width: 600px) {
  }
`;

export const StyledNavLinks = styled.div<NavLinksProps>`
  display: flex;
  align-items: stretch;
  justify-content: end;
  width: 20%;
  @media (max-width: 600px) {
    width: 100%;
    display: grid;
    grid-template-rows: 0fr;
    align-self: end;
    div {
      overflow: hidden;
    }
    transform: ${(props) =>
      props.displaynavlinks.toLowerCase() == "true"
        ? "translate(0,0px)"
        : "translate(150%,0px)"};
    grid-template-rows: ${(props) =>
      props.displaynavlinks.toLowerCase() == "true" ? "1fr" : "0fr"};
    transition: grid-template-rows 0.5s;
    transition: all 0.5s ease-out;
  }
`;

export const StyledHeaderNavCustomLink = styled(Link)`
  display:flex;
`;

export const StyledHeaderNavUL = styled.ul`
  display: flex;
  align-items: stretch;
`;