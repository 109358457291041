import axios, { AxiosError } from "axios";
import {
  itemDataFromDynamo,
  itemDataFromDynamoUnaltered,
} from "../Contexts/UserDataContext";

interface CompressionStream extends GenericTransformStream {}
type CompressionFormat = "deflate" | "deflate-raw" | "gzip";
declare var CompressionStream: {
  prototype: CompressionStream;
  new (format: CompressionFormat): CompressionStream;
};

function backendDynamoToFE(
  input: itemDataFromDynamoUnaltered
): itemDataFromDynamo {
  return {
    CustomFields: JSON.parse(input.CustomFields),
    Image: input.Image,
    InUse: input.InUse!,
    ItemId: input.ItemId,
    ParentId: input.ParentId!,
    Quantity: input.Quantity,
    Tags: JSON.parse(input.Tags), //convert from string to string[]
    Name: input.Name,
    Desc: input.Desc
  };
}

export async function GetUsersItems(id: string) {
  //Temp hashmaps for creting the ItemMap/SearchMap/ParentMap so we don't have to keep setting state
  var itemHashMap: Record<string, itemDataFromDynamo> = {};
  var searchHashMap: Record<string, Set<string>> = {};
  var parentHashMap: Record<string, Set<string>> = {};
  var subscriptionExpired: boolean = false;
  var errorGettingData: boolean = false;
  var subscriptionStatus: string = "";
  var subscriptionEndDate: string = "";
  var subscriptionCancelAtPeriodEnd: boolean = false;
  var logUserOut: boolean = false;
  //itemHashMap
  const results = await axios({
    method: "get",
    url: process.env.REACT_APP_AWS_ITEM_API_URL!,
    headers: {
      Authtoken: id,
      "Access-Control-Allow-Headers": "*",
      "Access-Control-Allow-Origin": "*",
      "Access-Control-Allow-Methods": "*",
      "Content-Type": "application/json",
    },
  })
    .then(async (res) => {
      //store response in DB Index
      //initDB(JSON.stringify(res.data));
      //await AddToDB(JSON.stringify(res.data));
      //ConvertCompressedToItems();

      subscriptionEndDate = res.headers["subscription-end-date"];
      subscriptionStatus = res.headers["subscription-type"];
      subscriptionCancelAtPeriodEnd =
        String(res.headers["cancel-at-period-end"]).toLowerCase() === "true";

      //create Item Hashmap
      const objects: itemDataFromDynamoUnaltered[] = res.data;
      for (var i = 0; i < objects.length; i++) {
        const convertedItem: itemDataFromDynamo = backendDynamoToFE(objects[i]);
        //Populate Item Hashmap - Using this so we don't set state over and over - Cound change to function
        itemHashMap[convertedItem.ItemId] = convertedItem;

        //Populate Search Hashmap with Name
        if (convertedItem.Name != null) {
          if (searchHashMap[convertedItem.Name.toLowerCase()] == null) {
            searchHashMap[convertedItem.Name.toLowerCase()] = new Set<string>([
              convertedItem.ItemId,
            ]);
          } else {
            searchHashMap[convertedItem.Name!.toLowerCase()].add(convertedItem.ItemId);
          }
        }

        //Populate Search Hashmap with Tags
        for (var j = 0; j < convertedItem.Tags.length; j++) {
          if (convertedItem.Tags[j] != null) {
            if (searchHashMap[convertedItem.Tags[j].toLowerCase()] == null) {
              searchHashMap[convertedItem.Tags[j].toLowerCase()] = new Set<string>([
                objects[i].ItemId,
              ]);
            } else {
              searchHashMap[convertedItem.Tags[j].toLowerCase()].add(objects[i].ItemId);
            }
          }
        }

        //Populate Parent Hashmap
        if (convertedItem.ParentId != null) {
          if (parentHashMap[convertedItem.ParentId] == null) {
            parentHashMap[convertedItem.ParentId] = new Set<string>([
              convertedItem.ItemId,
            ]);
          } else {
            parentHashMap[convertedItem.ParentId].add(convertedItem.ItemId);
          }
        }
      }

      return {
        itemHashMap,
        searchHashMap,
        parentHashMap,
        subscriptionExpired,
        errorGettingData,
        subscriptionStatus,
        subscriptionEndDate,
        subscriptionCancelAtPeriodEnd,
        logUserOut,
      };
    })
    .catch((error: Error | AxiosError) => {
      errorGettingData = true;
      if (axios.isAxiosError(error)) {
        if (error.response?.status == 599) {
          alert(error.response.data);
          subscriptionExpired = true;
          subscriptionEndDate = error.response.headers["subscription-end-date"];
          subscriptionStatus = error.response.headers["subscription-type"];
          subscriptionCancelAtPeriodEnd =
            error.response.headers["cancel-at-period-end"];
        }
        if (error.response?.status == 401) {
          alert(
            "The request to get items from the database returned 'Unauthorized'. " +
              "This typically occurs when the session has expired. Please log out and then log back in to resolve the issue. " +
              "If the problem persists contact Admin@WheresOur.com"
          );
        }
      } else {
        alert(
          "An unexpected error occured getting items from the database. Please log out and then log back in to resolve the issue. "+
          "If problem persists contact Admin@WheresOur.com."
        );
      }
    });
  return {
    itemHashMap,
    searchHashMap,
    parentHashMap,
    subscriptionExpired,
    errorGettingData,
    subscriptionStatus,
    subscriptionEndDate,
    subscriptionCancelAtPeriodEnd,
    logUserOut,
  };
}

export async function DeleteItems(
  itemIds: string[],
  loggedInUserId: string,
  subscriptionExpired: boolean
) {
  //Delete from database
  await axios({
    method: "delete",
    url: process.env.REACT_APP_AWS_ITEM_API_URL,
    data: { items: itemIds },
    headers: {
      Authtoken: localStorage.getItem(
        process.env.REACT_APP_ID_TOKEN_COGNITO + loggedInUserId + ".idToken"
      ),
      "Access-Control-Allow-Headers": "*",
      "Access-Control-Allow-Origin": "*",
      "Access-Control-Allow-Methods": "*",
      "Content-Type": "application/json",
    },
  });
}

//Used for both update and create new item
export function PostItem(
  itemData: itemDataFromDynamo,
  loggedInUserId: string,
  subscriptionExpired: boolean
) {
  return new Promise((resolve, reject) => {
    axios({
      method: "POST",
      url: process.env.REACT_APP_AWS_ITEM_API_URL,
      data: itemData,
      headers: {
        Authtoken: localStorage.getItem(
          process.env.REACT_APP_ID_TOKEN_COGNITO + loggedInUserId + ".idToken"
        ),
        "Access-Control-Allow-Headers": "*",
        "Access-Control-Allow-Origin": "*",
        "Access-Control-Allow-Methods": "*",
        "Content-Type": "application/json",
      },
    })
      .then((res) => {
        resolve(res);
      })
      .catch((error: Error|AxiosError) => {
        if(axios.isAxiosError(error) && error.response != null){
          reject(error.response!.status);
        }else{
          reject(0)
        }
      });
  });
}
