import React, { BaseSyntheticEvent } from "react";
import "../../Styles/TagsInput.css";
import { Trash } from "react-bootstrap-icons";
import { propTypes } from "react-bootstrap/esm/Image";
import {
  StyledTagsInputDiv,
  StyledTagsInputInput,
  StyledTagsInputTagItemContainer,
} from "../../styledComponents/TagsInput.Styled";

type testing = {
  tags: string[];
  setTags: React.Dispatch<React.SetStateAction<string[]>>;
};

function TagsInput(props: testing) {
  function handleKeyDown(e: any) {
    if (e.key !== "Enter") return;
    var space = new KeyboardEvent("keydown", { keyCode: 32, which: 32 });
    e.target.dispatchEvent(space);
    addTag(e)
  }
  function handleBlur(e: BaseSyntheticEvent) {
    addTag(e)
  }
  function addTag(e:any){
    const value = e.target.value;
    if (!value.trim()) return;
    if (props.tags == null) {
      props.setTags([value]);
    } else {
      props.setTags([...props.tags, value]);
    }
    e.target.value = null;
  }
  function RemoveTag(index: any) {
    props.setTags(props.tags.filter((el, i) => i !== index));
  }

  return (
    <StyledTagsInputDiv>
      {props.tags != null &&
        props.tags.length > 0 &&
        props.tags.map((tag, index) => (
          <StyledTagsInputTagItemContainer key={index}>
            <span className="text">{tag}</span>
            <span className="close" onClick={() => RemoveTag(index)}>
              <Trash />
            </span>
          </StyledTagsInputTagItemContainer>
        ))}
      <StyledTagsInputInput
        onKeyDown={handleKeyDown}
        type="text"
        className="tags-input"
        placeholder="Enter Tag"
        data-lpignore="true"
        autoComplete="off"
        autoCorrect="false"
        maxLength={30}
        onBlur={handleBlur}
      />
    </StyledTagsInputDiv>
  );
}

export default TagsInput;
