import LoginPopup from "./LoginPopup";
import SignupPopup from "./SignupPopup";
import ForgotPasswordPopup from "./ForgotPasswordPopup";
import {
  LoginStageContextProvider,
  useLoginStageContext,
  loginStageType,
} from "../Contexts/LoginStageContext";

function LoginFlowPopup() {
  const { loginStage } = useLoginStageContext();
  return (
    <>
      {loginStage === "login" && (
        <>
          <LoginPopup />
        </>
      )}
      {loginStage === "register" && (
        <>
          <SignupPopup />
        </>
      )}
      {loginStage === "forgot_password" && (
        <>
          <ForgotPasswordPopup />
        </>
      )}
    </>
  );
}

export default LoginFlowPopup;
