import "../Styles/navStyles.css";
import { Link, useMatch, useResolvedPath } from "react-router-dom";
import { useAccountContext, cognitoUserAttributes } from "../Contexts/Account";
import useScrollListener from "../Contexts/UseScrollListener";
import {
  itemDataFromDynamo,
  useUserDataContext,
} from "../Contexts/UserDataContext";
import { SyntheticEvent, useEffect, useState } from "react";
import {
  calculateChildren,
  calculateParentPath,
} from "../utilities/CalculateItems";
import {
  AddItemButton,
  Button,
  InUseButton,
} from "../styledComponents/Buttons.styled";
import { createIncrementalProgram } from "typescript";
import { LocationPath } from "../styledComponents/Header2.styled";
import { LocationPathItem } from "../styledComponents/Header.styled";
import {
  StyledHeaderLoggedIn,
  StyledHeaderNav,
  StyledHeaderNavCustomLink,
  StyledHeaderNavLoc,
  StyledHeaderNavLocPath,
  StyledHeaderNavUL,
  StyledHeaderNonNav,
  StyledNavLinks,
} from "../styledComponents/HeaderNav.styled";
import {
  StyledSearchArrowRight,
  StyledSearchButtonContainer,
  StyledSearchForm,
  StyledSearchInputContainer,
  StyledSearchContainer,
} from "../styledComponents/Search.styled";
import { Search, ArrowRight, Key } from "react-bootstrap-icons";

type navBarLink = {
  to: string;
  label: string;
};

export function HeaderNav() {
  const { userCognitoAttrubutes, getSession } = useAccountContext();
  const scroll = useScrollListener();
  const {
    curParent,
    itemMap,
    setCurParent,
    setCurItemData,
    setParentToBe,
    setupgradedItemFormOpen,
    searchActive,
    itemSearchInput,
    recalculateCurrentArray,
    parentMap,
    searchOpen,
    setSearchOpen,
    displayNav,
    locationSearchInput,
    searchItemsInUse,
    displayNavLinks,
    subscriptionExpired,
  } = useUserDataContext();

  function addItem() {
    setCurItemData({});
    setParentToBe(curParent);
    setupgradedItemFormOpen(true);
  }

  function editItem(item: itemDataFromDynamo) {
    setParentToBe(item.ParentId);
    setCurItemData(item);
    setupgradedItemFormOpen(true);
  }

  useEffect(() => {
    // console.log("component rerendered");
    // console.log("nav", displayNav);
    // console.log("Links", displayNavLinks);
  });

  return (
    <StyledHeaderNav displaynav={displayNav.toString()} className="nav">
      {localStorage.getItem("CognitoIdToken") && (
        <StyledHeaderLoggedIn>
          <StyledHeaderNavLoc>
            {searchActive && (
              <div>
                Results for{" "}
                {locationSearchInput != ""
                  ? "Location Search: '" + locationSearchInput + "'"
                  : ""}{" "}
                {locationSearchInput != "" && itemSearchInput != ""
                  ? " and "
                  : ""}{" "}
                {itemSearchInput != ""
                  ? "Item Search: '" + itemSearchInput + "'"
                  : ""}
                {searchItemsInUse && "items currently in use"}
              </div>
            )}
            {!subscriptionExpired && (
              <>
                {curParent && !searchActive && (
                  <LocationPath key={"locationPath"}>
                    Location Path -{" "}
                    {curParent == "MASTER" && (
                      <LocationPathItem key="MASTER">MASTER</LocationPathItem>
                    )}
                    {curParent != "MASTER" &&
                      calculateParentPath(itemMap, curParent).map(
                        (s, index) => (
                          <>
                            {s == curParent && (
                              <LocationPathItem
                                key={index}
                                onClick={() => editItem(itemMap[s])}
                              >
                                <strong>
                                  {s != "MASTER"
                                    ? itemMap[s].Name != null &&
                                      itemMap[s].Name?.trim() != ""
                                      ? " / " + itemMap[s].Name
                                      : " / UNTITLED"
                                    : "MASTER"}
                                </strong>
                              </LocationPathItem>
                            )}
                            {(s == "MASTER" || s != curParent) && (
                              <LocationPathItem
                                key={index}
                                onClick={() =>
                                  recalculateCurrentArray(parentMap, s, itemMap)
                                }
                              >
                                {s != "MASTER"
                                  ? itemMap[s].Name != null &&
                                    itemMap[s].Name?.trim() != ""
                                    ? " / " + itemMap[s].Name
                                    : " / UNTITLED"
                                  : "MASTER"}
                              </LocationPathItem>
                            )}
                          </>
                        )
                      )}{" "}
                    <StyledSearchArrowRight />
                    <AddItemButton onClick={() => addItem()}>
                      Add Item
                    </AddItemButton>
                  </LocationPath>
                )}
              </>
            )}
            {subscriptionExpired && <div>Your subscription has expired!</div>}
          </StyledHeaderNavLoc>
          <SearchItems />
          <StyledNavLinks displaynavlinks={displayNavLinks.toString()}>
            <StyledHeaderNavUL>
              <CustomLink to="/Home" label="Home" />
              <CustomLink to="/FAQ" label="Guide" />
              <CustomLink to="/MyItems" label="My Items" />
            </StyledHeaderNavUL>
          </StyledNavLinks>
        </StyledHeaderLoggedIn>
      )}
      {localStorage.getItem("CognitoIdToken") == null && (
        <>
          <StyledHeaderNavLoc>
            You are not logged in. Please login or register a new account.
          </StyledHeaderNavLoc>
          <StyledNavLinks displaynavlinks={displayNavLinks.toString()}>
            <StyledHeaderNavUL>
              <CustomLink to="/Home" label="Home" />
              <CustomLink to="/FAQ" label="Guide" />
              <CustomLink to="/MyItems" label="My Items" />
            </StyledHeaderNavUL>
          </StyledNavLinks>
        </>
      )}
    </StyledHeaderNav>
  );
}

function CustomLink({ to, label }: navBarLink) {
  const resolvedPath = useResolvedPath(to);
  const isActive = useMatch({ path: resolvedPath.pathname, end: true });
  return (
    <li className={isActive ? "active" : ""}>
      <StyledHeaderNavCustomLink to={to}>{label}</StyledHeaderNavCustomLink>
    </li>
  );
}

function SearchItems() {
  const [curItemArrayBeforeSearch, setCurItemArrayBeforeSearch] = useState<
    itemDataFromDynamo[]
  >([]);
  //const [searchItemsInUse, setSearchItemsInUse] = useState<boolean>(false);

  const {
    setCurItemArray,
    searchMap,
    itemMap,
    setSearchActive,
    curParent,
    recalculateCurrentArray,
    parentMap,
    itemSearchValue,
    setItemSearchValue,
    setItemSearchInput,
    locationSearchValue,
    setLocationSearchValue,
    setLocationSearchInput,
    searchActive,
    searchItemsInUse,
    setSearchItemsInUse,
    CancelSearch,
    setItemsInView,
  } = useUserDataContext();
  const handleItemSearchChange = (e: React.ChangeEvent<HTMLInputElement>) => {
    e.preventDefault();
    setItemSearchValue(e.target.value);
  };
  const handleLocationSearchChange = (
    e: React.ChangeEvent<HTMLInputElement>
  ) => {
    e.preventDefault();
    setLocationSearchValue(e.target.value);
  };

  const viewItemsInUse = () => {
    CancelSearch();
    if (!searchItemsInUse) {
      setSearchItemsInUse(true);
      let inUseResultsArray: itemDataFromDynamo[] = [];
      for (const key in itemMap) {
        if (itemMap[key].InUse) {
          inUseResultsArray.push(itemMap[key]);
        }
      }
      if (inUseResultsArray.length > 0) {
        setCurItemArray(inUseResultsArray);
        setSearchActive(true);
      } else {
        alert("No Items Currently In Use");
        CancelSearch();
        recalculateCurrentArray(parentMap, curParent, itemMap, false);
      }
    } else {
      CancelSearch();
      recalculateCurrentArray(parentMap, curParent, itemMap, false);
    }
  };

  function clearSearch() {
    CancelSearch();
    recalculateCurrentArray(parentMap, curParent, itemMap, false);
  }

  const handleSubmit = (e: SyntheticEvent) => {
    e.preventDefault();
    setSearchItemsInUse(false);
    let seachResultsArray: itemDataFromDynamo[] = [];
    let itemSearchResultsArray: itemDataFromDynamo[] = [];
    let locationSeachResultsArray: itemDataFromDynamo[] = [];
    let locationSeachExactMatchResultsArray: itemDataFromDynamo[] = [];
    let locationSearchResultIds: string[] = [];
    var itemSearchArray: string[] = itemSearchValue
      .split(";")
      .filter(function (v) {
        return v.trim() != "";
      })
      .map((v) => {
        return v.trim();
      });
    var locationSearchArray: string[] = locationSearchValue
      .split(";")
      .filter(function (v) {
        return v.trim() != "";
      })
      .map((v) => {
        return v.trim();
      });
    var locationMap: Record<string, itemDataFromDynamo> = itemMap;
    if (locationSearchArray.length > 0 || itemSearchArray.length > 0) {
      // console.log(itemSearchArray);
      // console.log(locationSearchArray);
      setSearchActive(true);
      setItemSearchInput(itemSearchValue);
      setLocationSearchInput(locationSearchValue);
      if (locationSearchArray.length > 0) {
        // console.log("Filter Location Map");
        for (const key in searchMap) {
          locationSearchArray.forEach((searchVal) => {
            if (
              key.toLowerCase().includes(searchVal.toLowerCase())
            ) {
              // console.log("bbb - ", searchMap[key]);
              searchMap[key.toLowerCase()].forEach((itemId) => {
                // console.log(itemMap[itemId]);
                // console.log(parentMap[itemId]);
                // console.log(locationSeachResultsArray);
                if (parentMap[itemId] != null) {
                  // console.log(parentMap[itemId]);

                  parentMap[itemId].forEach((id) => {
                    if (
                      parentMap[id] != null &&
                      !locationSeachResultsArray.includes(itemMap[key])
                    ) {
                      locationSeachResultsArray.push(itemMap[id]);
                    }

                    locationSearchResultIds = locationSearchResultIds.concat(
                      calculateChildren(parentMap, id)
                    );
                    locationSearchResultIds.push(id);
                  });
                }
                if (
                  parentMap[itemId] != null &&
                  !locationSeachResultsArray.includes(itemMap[itemId])
                ) {
                  locationSeachResultsArray.push(itemMap[itemId]);
                }
                locationSearchResultIds.push(itemId);
                if (
                  parentMap[itemId] != null &&
                  !locationSeachExactMatchResultsArray.includes(itemMap[itemId])
                ) {
                  locationSeachExactMatchResultsArray.push(itemMap[itemId]);
                }
              });
            }
          });
        }
        // console.log("locationSeachResultsArray = ", locationSeachResultsArray);
        // console.log("locationSearchResultIds = ", locationSearchResultIds);
      }
      if (
        itemSearchArray.length == 1 &&
        itemSearchArray[0].toLowerCase() == "l*"
      ) {
        for (const key in itemMap) {
          if (
            itemMap[key] != null &&
            locationSearchResultIds.includes(itemMap[key].ParentId) &&
            !itemSearchResultsArray.includes(itemMap[key])
          ) {
            if (parentMap[key] != null) {
              itemSearchResultsArray.push(itemMap[key]);
            }
          }
        }
      }
      if (
        itemSearchArray.length == 1 &&
        itemSearchArray[0].toLowerCase() == "i*"
      ) {
        for (const key in itemMap) {
          if (
            itemMap[key] != null &&
            locationSearchResultIds.includes(itemMap[key].ParentId) &&
            !itemSearchResultsArray.includes(itemMap[key])
          ) {
            if (parentMap[key] == null) {
              itemSearchResultsArray.push(itemMap[key]);
            }
          }
        }
      }
      if (
        itemSearchArray.length == 1 &&
        itemSearchArray[0].toLowerCase() == "*"
      ) {
        for (const key in itemMap) {
          if (
            itemMap[key] != null &&
            locationSearchResultIds.includes(itemMap[key].ParentId) &&
            !itemSearchResultsArray.includes(itemMap[key])
          ) {
            itemSearchResultsArray.push(itemMap[key]);
          }
        }
      }
      if (itemSearchArray.length == 1 && itemSearchArray[0] == "UNTITLED") {
        for (const key in itemMap) {
          if (
            itemMap[key] != null &&
            (itemMap[key].Name == null || itemMap[key].Name == "") &&
            !itemSearchResultsArray.includes(itemMap[key])
          ) {
            itemSearchResultsArray.push(itemMap[key]);
          }
        }
      }
      if (itemSearchArray.length > 0) {
        for (const key in searchMap) {
          itemSearchArray.forEach((searchVal) => {
            if (
              key.toLowerCase().includes(searchVal.toLowerCase())
            ) {
              searchMap[key].forEach((itemId) => {
                if (
                  itemMap[itemId] != null &&
                  (locationSeachResultsArray.length == 0 ||
                    locationSearchResultIds.includes(
                      itemMap[itemId].ParentId
                    )) &&
                  !itemSearchResultsArray.includes(itemMap[itemId])
                ) {
                  itemSearchResultsArray.push(itemMap[itemId]);
                }
              });
            }
          });
        }
      }
      setItemsInView(parseInt(process.env.REACT_APP_ITEM_VIEW_INTERVAL!));
      setCurItemArray(
        itemSearchArray.length > 0
          ? itemSearchResultsArray
          : locationSeachExactMatchResultsArray
      );
      window.scrollTo(0, 0);
    } else {
      clearSearch();
    }
  };

  return (
    <StyledSearchContainer>
      <StyledSearchForm onSubmit={handleSubmit}>
        <StyledSearchInputContainer key="searchContainer">
          <input
            type="text"
            placeholder="Location Search"
            onChange={handleLocationSearchChange}
            value={locationSearchValue}
            data-lpignore="true"
            key="locSearch"
          />
          <input
            type="text"
            placeholder="Item Search"
            onChange={handleItemSearchChange}
            value={itemSearchValue}
            data-lpignore="true"
            key="itemSearch"
          />
        </StyledSearchInputContainer>
        <StyledSearchButtonContainer key="searchButtonContainer">
          <AddItemButton type="submit">
            <Search />
          </AddItemButton>
          {/* <AddItemButton onClick={()=>setSearchItemsInUse(!searchItemsInUse)}>View Items In Use <input checked={searchItemsInUse} type="checkbox" readOnly/></AddItemButton> */}

          {searchActive && (
            <AddItemButton type="button" onClick={clearSearch}>
              Clear
            </AddItemButton>
          )}
        </StyledSearchButtonContainer>
      </StyledSearchForm>
      <InUseButton onClick={viewItemsInUse}>
        In Use? <input checked={searchItemsInUse} type="checkbox" readOnly />
      </InUseButton>
    </StyledSearchContainer>
  );
}
