import {
  StyledGrid,
  StyledGridCell,
  StyledHomeMainDiv,
  StyledImg,
  StyledInfoDiv,
} from "../styledComponents/Home";
import Basement1 from "../Shared/Images/StockPhotos/Basement1.jpg";
import Moving3 from "../Shared/Images/StockPhotos/Moving3.jpg";
import StorageUnit1 from "../Shared/Images/StockPhotos/StorageUnit1.jpg";
import Tools1 from "../Shared/Images/StockPhotos/Tools1.jpg";
import Tools2 from "../Shared/Images/StockPhotos/Tools2.jpg";
import Workshop1 from "../Shared/Images/StockPhotos/Workshop1.jpeg";
import Organize1 from "../Shared/Images/StockPhotos/Organize1.jpg";
import SmallApartment1 from "../Shared/Images/StockPhotos/SmallApartment1.jpg";
import Notes1 from "../Shared/Images/StockPhotos/notes1.jpg";

export default function Home() {
  return (
    <StyledHomeMainDiv>
      <StyledInfoDiv>
        <h1>Welcome to WheresOur</h1>
        <p>
          WheresOur is an oranization and home inventory application intended
          for people that need help keeping track of their belongings. The
          application allows you to add nested items in a way that is simple and
          quick. Once you add belongings to the app you can then easily find
          items with a simple search. This applicaiton is great for organizing a
          house, packing for a move, managing items in a storage unit and so
          much more.
        </p>
      </StyledInfoDiv>
      <StyledGrid>
        <StyledGridCell>
          <StyledImg src={Organize1} />
          <h3>Organizing Doesn't Mean Throw Everything Away</h3>
          <p>
            Every piece of advice on organizing starts the same way... Throw
            stuff away. While that might be good advice in some cases, sometimes
            you don't want to throw stuff away. Maybe you haven't used something
            in a while, but is that because you didn't want to or because you
            didn't know where it was? WheresOur aims to help you organize
            differently. Instead of throwing things out, keep track of them so
            you can find them later.
          </p>
        </StyledGridCell>
        <StyledGridCell>
          <StyledImg src={SmallApartment1} />
          <h3>Organizing Small Spaces</h3>
          <p>
            When we moved accross the country we packed 75% of our house into a
            10X15 storage unit, so the issue with organizing isn't the lack of
            space. The biggest issue is being able to remember where things are.
            By using WheresOur, you can utilize spaces like under beds and
            awkward closets without losing anything. Simply add these locations
            in the app and you will be able to find every item.
          </p>
        </StyledGridCell>
        <StyledGridCell>
          <StyledImg src={Basement1} />
          <h3>Basements and Garages</h3>
          <p>
            Basements and garages are great places to store things aren't used
            on a daily basis. Often times these locations are underutilized or
            accumulate too much clutter. Organizing your things with WheresOur
            can give you the opportunity to use these spaces more efficiently
            while simultaneously allowing you to find anything stored in these
            areas.
          </p>
        </StyledGridCell>
        <StyledGridCell>
          <StyledImg src={Workshop1} />
          <h3>Attics</h3>
          <p>
            Attics can be terrific places to store items and offer valuable
            storage space when other options are limited. One problem with
            attics is that they are typically difficult to access and
            uncomfortable in the peak of summer. WheresOur can’t solve the issue
            of heat, but it can help you quickly find the location of the item
            you’re looking for to reduce your time spent in the attic.
          </p>
        </StyledGridCell>
        <StyledGridCell>
          <StyledImg src={StorageUnit1} />
          <h3>Storage Units</h3>
          <p>
            Storage units can get expensive, so if you can, try to utilize the
            spaces you have already. That said, sometimes they simply can{"'"}t
            be avoided. When that is the case, knowing where items are in the
            unit can be a real benefit. WheresOur allows you to take pictures of
            every item so you can know where a given box or bin is located in
            the unit. We had a unit after moving accross the country and would
            go looking for things every couple weeks. Knowing where our stuff
            was would have been especially beneficial!
          </p>
        </StyledGridCell>
        <StyledGridCell>
          <StyledImg src={Tools2} />
          <h3>Tools</h3>
          <p>
            We built our own house and have a ton of tools. While we typically
            know where to find the miter saw and the last place we used the air
            compressor, we have run into issues finding those one-off items that
            aren't used all the time. This is where WheresOur excels! Once on
            the app, we can put items in any box, bin, or any other container
            and know exactly where to find it. Need the grout remover tool?
            Check the app. Looking for those ratchet straps? Check the app.
          </p>
        </StyledGridCell>
        <StyledGridCell>
          <StyledImg src={Tools1} />
          <h3>Everything In Its Place</h3>
          <p>
            A place for everything and everything in it's place is a great idea.
            Implementation is a bit more difficult. Does every little item have
            it's own place? How would I remember where I put some little trinket
            or tool after a year? With WheresOur, you can put it in any box and
            in any crevice you choose. Then when you need it in a year just
            search for it. The app even has an 'In Use' feature where you can
            see what items you are currently using and where they should go when
            you put them back.
          </p>
        </StyledGridCell>
        <StyledGridCell>
          <StyledImg src={Moving3} />
          <h3>Moving</h3>
          <p>
            Moving can be stressful, but packing doesn't need to be. When
            packing, a general rule of thumb is to keep like things together.
            This works in some scenarios, but sometimes you just need to pack up
            what you don't plan to use before the move. This might mean that
            kitchen items end up with stuff from the bathroom. With WheresOur,
            you can pack in any way that works for you. Just take a picture,
            name the item when packing, and move on. The best part is, if you
            need something just search for it and the app with lead you right to
            it.
          </p>
        </StyledGridCell>
        <StyledGridCell>
          <StyledImg src={Notes1} />
          <h3>Tasks and Reminders</h3>
          <p>
            In addition to tracking items, WheresOur can be used to track when
            you last completed household chores, like changing HVAC air filters
            or the last oil change in the car. How about keeping an eye on when
            credit card awards (free night, companion ticket) expire? Yep, the
            app can do that too! Through the use of custom fields, any
            information can be added so that you always have it at your
            fingertips.
          </p>
        </StyledGridCell>
      </StyledGrid>
      <StyledInfoDiv>
        <h1>Start a free trial today</h1>
        <p>
          Click 'Login' at the top of the page to register for a free 14-day
          trial today.
        </p>
      </StyledInfoDiv>
    </StyledHomeMainDiv>
  );
}
