import {
  createContext,
  useContext,
  useState,
  SetStateAction,
  useEffect,
} from "react";
import { cognitoUserAttributes, useAccountContext } from "./Account";
import {
  GetUsersItems,
  DeleteItems,
  PostItem,
} from "../utilities/DynamoDBCrud";
import { calculateChildren } from "../utilities/CalculateItems";
import { saveImageToS3 } from "../utilities/saveImageToS3";
import axios, { AxiosError } from "axios";

export interface cFRow {
  id: number;
  key: string | null;
  value: string | null;
}

export type itemDataFromDynamoUnaltered = {
  CustomFields: string;
  Image?: string | undefined;
  InUse?: boolean;
  ItemId: string;
  ParentId: string;
  Quantity: string;
  Tags: string;
  UserId?: string | undefined;
  Name: string | undefined;
  Desc: string | undefined;
};

//Altered to change 'Parent' in dynamo table to 'ParentId'
export type itemDataFromDynamo = {
  CustomFields: cFRow[];
  Image?: string | undefined;
  InUse: boolean;
  ItemId: string;
  ParentId: string;
  Quantity: string;
  Tags: string[];
  //UserId: string;
  Name?: string | undefined;
  Desc?: string | undefined;
};

type UserData = {
  itemDataFromDynamo: itemDataFromDynamo | undefined;
  setItemDataFromDynamo: React.Dispatch<
    SetStateAction<itemDataFromDynamo | undefined>
  >;
  newItemPopupOpen: boolean;
  setNewItemPopupOpen: React.Dispatch<SetStateAction<boolean>>;
  editItemPopupOpen: boolean;
  setEditItemPopupOpen: React.Dispatch<SetStateAction<boolean>>;
  itemToDelete: string;
  setItemToDelete: React.Dispatch<SetStateAction<string>>;
  itemToMove: string;
  setItemToMove: React.Dispatch<SetStateAction<string>>;
  curItemData: itemDataFromDynamo | {};
  setCurItemData: React.Dispatch<SetStateAction<itemDataFromDynamo | {}>>;
  curItemArray: itemDataFromDynamo[];
  setCurItemArray: React.Dispatch<SetStateAction<itemDataFromDynamo[]>>;
  isSearchResult: boolean;
  setIsSearchResult: React.Dispatch<SetStateAction<boolean>>;
  curParent: string;
  setCurParent: React.Dispatch<SetStateAction<string>>;
  parentToBe: string;
  setParentToBe: React.Dispatch<SetStateAction<string>>;
  itemMap: Record<string, itemDataFromDynamo>;
  setItemMap: React.Dispatch<
    SetStateAction<Record<string, itemDataFromDynamo>>
  >;
  parentMap: Record<string, Set<string>>;
  setParentMap: React.Dispatch<SetStateAction<Record<string, Set<string>>>>;
  searchMap: Record<string, Set<string>>;
  setSearchMap: React.Dispatch<SetStateAction<Record<string, Set<string>>>>;
  changeParent: (
    parentId: string | null,
    itemId: string,
    newParentId: string | null
  ) => void;
  deleteItem: (itemId: string, deleteChildren: boolean) => void;
  getItemLocation: (itemId: string) => string[];
  getItemChildren: (itemId: string) => string[];
  generateLocationStructure: (
    parentId: string,
    obj: Record<string, { children: Set<string>; level: number }>,
    level: number
  ) => void;
  moveItem: (itemId: string, location: string) => void;
  upgradedItemFormOpen: boolean;
  setupgradedItemFormOpen: React.Dispatch<SetStateAction<boolean>>;
  searchActive: boolean;
  setSearchActive: React.Dispatch<SetStateAction<boolean>>;
  logoutUser: () => void;
  recalculateCurrentArray: (
    pMap: Record<string, Set<string>>,
    parentId: string,
    iMap: Record<string, itemDataFromDynamo>,
    searchActiveVal?: boolean
  ) => void;
  itemSearchInput: string;
  setItemSearchInput: React.Dispatch<SetStateAction<string>>;
  itemSearchValue: string;
  setItemSearchValue: React.Dispatch<SetStateAction<string>>;
  locationSearchInput: string;
  setLocationSearchInput: React.Dispatch<SetStateAction<string>>;
  locationSearchValue: string;
  setLocationSearchValue: React.Dispatch<SetStateAction<string>>;
  searchOpen: boolean;
  setSearchOpen: React.Dispatch<SetStateAction<boolean>>;
  popupMessage: string;
  setPopupMessage: React.Dispatch<SetStateAction<string>>;
  displayNav: boolean;
  setDisplayNav: React.Dispatch<SetStateAction<boolean>>;
  displayNavLinks: boolean;
  setDisplayNavLinks: React.Dispatch<SetStateAction<boolean>>;
  itemToMoveOrUse: string;
  setItemToMoveOrUse: React.Dispatch<SetStateAction<string>>;
  checkoutPopupOpen: boolean;
  setCheckoutPopupOpen: React.Dispatch<SetStateAction<boolean>>;
  subscriptionExpired: boolean;
  setSubscriptionExpired: React.Dispatch<SetStateAction<boolean>>;
  subscriptionEndDate: string;
  setSubscriptionEndDate: React.Dispatch<SetStateAction<string>>;
  subscriptionStatus: string;
  setSubscriptionStatus: React.Dispatch<SetStateAction<string>>;
  subscriptionCancelAtPeriodEnd: boolean;
  setSubscriptionCancelAtPeriodEnd: React.Dispatch<SetStateAction<boolean>>;
  // SaveItem: (
  //   itemData: itemDataFromDynamo,
  //   uuid: string,
  //   close: boolean,
  //   fileUrlMainImage: string,
  //   userId: string,
  //   recalcCurrentArray: boolean
  // ) => void;
  SaveItem: (
    itemData: itemDataFromDynamo,
    uuid: string,
    close: boolean,
    fileUrlMainImage: string,
    userId: string,
    recalcCurrentArray: boolean
  ) => Promise<number>;
  searchItemsInUse: boolean;
  setSearchItemsInUse: React.Dispatch<SetStateAction<boolean>>;
  CancelSearch: () => void;
  isLoading: boolean;
  setIsLoading: React.Dispatch<SetStateAction<boolean>>;
  itemsInView: number;
  setItemsInView: React.Dispatch<SetStateAction<number>>;
};

const UserDataContext = createContext<UserData | null>(null);

const UserData = (props: any) => {
  const [itemDataFromDynamo, setItemDataFromDynamo] =
    useState<itemDataFromDynamo>();
  const [newItemPopupOpen, setNewItemPopupOpen] = useState(false);
  const [editItemPopupOpen, setEditItemPopupOpen] = useState(false);
  const [upgradedItemFormOpen, setupgradedItemFormOpen] = useState(false);
  const [itemToDelete, setItemToDelete] = useState<string>("");
  const [itemToMove, setItemToMove] = useState<string>("");
  const [itemToMoveOrUse, setItemToMoveOrUse] = useState<string>("");
  const [curItemData, setCurItemData] = useState<itemDataFromDynamo | {}>({});
  const [curItemArray, setCurItemArray] = useState<itemDataFromDynamo[]>([]);
  const [isSearchResult, setIsSearchResult] = useState<boolean>(false);
  const [curParent, setCurParent] = useState("MASTER");
  const [parentToBe, setParentToBe] = useState("MASTER");
  const [itemMap, setItemMap] = useState<Record<string, itemDataFromDynamo>>(
    {}
  );
  const [parentMap, setParentMap] = useState<Record<string, Set<string>>>({});
  const [searchMap, setSearchMap] = useState<Record<string, Set<string>>>({});
  const [searchActive, setSearchActive] = useState(false);
  const [itemSearchInput, setItemSearchInput] = useState("");
  const [itemSearchValue, setItemSearchValue] = useState("");
  const [locationSearchInput, setLocationSearchInput] = useState("");
  const [locationSearchValue, setLocationSearchValue] = useState("");
  const [searchOpen, setSearchOpen] = useState(false);
  const [popupMessage, setPopupMessage] = useState("");
  const [displayNav, setDisplayNav] = useState(true);
  const [displayNavLinks, setDisplayNavLinks] = useState(true);
  const [searchItemsInUse, setSearchItemsInUse] = useState(false);
  const [isLoading, setIsLoading] = useState(false);
  const [checkoutPopupOpen, setCheckoutPopupOpen] = useState(false);
  const [subscriptionExpired, setSubscriptionExpired] = useState(false);
  const [subscriptionEndDate, setSubscriptionEndDate] = useState("");
  const [subscriptionStatus, setSubscriptionStatus] = useState("");
  const [subscriptionCancelAtPeriodEnd, setSubscriptionCancelAtPeriodEnd] =
    useState(false);
  const [itemsInView, setItemsInView] = useState(
    parseInt(process.env.REACT_APP_ITEM_VIEW_INTERVAL!)
  );
  // const [searchType, setSearchType] = useState(All);
  const {
    loggedInUserCognitoIdToken,
    userCognitoAttrubutes,
    logout,
    setLoggedInUserCognitoIdToken,
  } = useAccountContext();

  //When the Cognito User Id changes we need to get the data from dynamo and
  //reset the ItemMap, ParentMap, and SearchMap
  useEffect(() => {
    async function getUsers() {
      if (
        loggedInUserCognitoIdToken !== null &&
        loggedInUserCognitoIdToken !== undefined &&
        loggedInUserCognitoIdToken !== ""
      ) {
        try {
          setIsLoading(true);
          var userItemInfo = await GetUsersItems(loggedInUserCognitoIdToken);
          if (userItemInfo.logUserOut) {
            logoutUser();
          } else {
            setSubscriptionStatus(userItemInfo.subscriptionStatus);
            setSubscriptionEndDate(userItemInfo.subscriptionEndDate);
            setSubscriptionExpired(userItemInfo.subscriptionExpired);
            setSubscriptionCancelAtPeriodEnd(
              userItemInfo.subscriptionCancelAtPeriodEnd
            );
            if (!userItemInfo.errorGettingData) {
              recalculateCurrentArray(
                userItemInfo.parentHashMap,
                curParent,
                userItemInfo.itemHashMap
              );
              setItemMap(userItemInfo.itemHashMap);
              setParentMap(userItemInfo.parentHashMap);
              setSearchMap(userItemInfo.searchHashMap);
            } else {
              setItemMap({});
              setParentMap({});
              setSearchMap({});
            }
          }
        } catch (e) {
          setItemMap({});
          setParentMap({});
          setSearchMap({});
        } finally {
          setIsLoading(false);
          // setTimeout(() => {
          //   setIsLoading(false);
          // }, Math.max(Date.now() - startTime, 0));
        }
      }
    }
    getUsers();
  }, [loggedInUserCognitoIdToken]);

  //Routes delete item to based on if children should be deleted
  async function deleteItem(itemId: string, deleteChildren: boolean) {
    var result;
    var itemIds: string[] = [];
    try {
      if (deleteChildren) {
        itemIds = calculateChildren(parentMap, itemId);
        itemIds.push(itemId);
        result = deleteMultipleItems(itemIds);
      } else {
        itemIds.push(itemId);
        result = await deleteSingleItem(itemIds);
      }
      recalculateCurrentArray(
        result.parentHashMap,
        result.parentId,
        result.itemHashMap
      );
    } catch (error) {
      console.error("Error in function deleteItem - ", error);
      throw error;
    }
  }

  async function deleteSingleItem(itemIds: string[]): Promise<{
    itemHashMap: Record<string, itemDataFromDynamo>;
    parentHashMap: Record<string, Set<string>>;
    toDelete: never[];
    parentId: string;
  }> {
    const item: itemDataFromDynamo = itemMap[itemIds[0]];
    const parent: itemDataFromDynamo = itemMap[item.ParentId!];
    var itemHashMap: Record<string, itemDataFromDynamo> = {};
    var parentHashMap: Record<string, Set<string>> = {};
    var parentId: string = item.ParentId;
    setIsLoading(true);

    //Delete the data from Dynamo
    await DeleteItems(
      itemIds,
      (userCognitoAttrubutes as cognitoUserAttributes).sub!,
      subscriptionExpired
    )
      .then(async (res: any) => {
        //Update the parent map
        itemHashMap = deleteItemsFromItemMap(itemIds, item.ParentId!);
        //Recalculate the parent map with the new Item Hash Map
        parentHashMap = recalculateParentMap(itemHashMap);
        //Update children parentId value in database
        var children = calculateChildren(parentMap, item.ItemId);
        children.forEach(async (child) => {
          await moveItem(child, parentId, parentHashMap, itemHashMap);
        });

        //If parent now contains no children then curParent state needs to be set to the parent of the deleted item's parent
        parentId = item.ParentId!;
        if (
          parentHashMap[item.ParentId!] == null &&
          item.ParentId! !== "MASTER"
        ) {
          parentId = parent.ParentId!;
        }
        setItemToDelete("");
        setupgradedItemFormOpen(false);
      })
      .catch((reason: any) => {
        itemHashMap = itemMap;
        parentHashMap = parentMap;
        alert(
          "Error deleting item from database. Please check your internet connection and try again. Contact Admin@WheresOur.com if the issue persists."
        );
      })
      .finally(() => {
        setIsLoading(false);
      });
    return { itemHashMap, parentHashMap, toDelete: [], parentId };
  }

  function changeParent(
    parentId: string | null,
    itemId: string,
    newParentId: string | null
  ) {
    //Create new map to replace parent
    var parentHashMap: Record<string, Set<string>> = {};
    //If parentid not in parent map it needs to be added
    if (newParentId != null && parentMap[newParentId] == null) {
      parentHashMap[newParentId] = new Set<string>();
    }
    for (const key in parentMap) {
      //delete the item from the current parent
      if (key === parentId) {
        //Add the children of the item to the parent
        for (const s in parentMap[itemId]) {
          parentMap[key].add(s);
        }
        parentMap[key].delete(itemId);

        if (parentMap[key].size === 0) {
          //If item is no longer a parent we want to remove it from the Map
          continue;
        }
      }
      //Add item to set of new parent
      if (key === newParentId) {
        parentMap[key].add(itemId);
      }
      parentHashMap[key] = parentMap[key];
    }
    return parentHashMap;
  }

  function deleteItemsFromItemMap(
    itemIds: string[],
    parentId: string
  ): Record<string, itemDataFromDynamo> {
    //get all children
    var children: Set<string> = parentMap[itemIds[0]];
    //Create new map to replace current
    var itemHashMap: Record<string, itemDataFromDynamo> = {};

    for (const key in itemMap) {
      //Continue the for loop when you hit the itemId
      if (itemIds.includes(key)) {
        continue;
      }
      //Update the parent id of children to the item's parent
      if (children != null && children.has(key)) {
        itemMap[key].ParentId = parentId;
      }
      itemHashMap[key] = itemMap[key];
    }
    return itemHashMap;
  }

  function deleteMultipleItems(itemIds: string[]) {
    //Create new map to replace current
    var itemHashMap: Record<string, itemDataFromDynamo> = {};
    var parentHashMap: Record<string, Set<string>> = {};
    //The item being deleted is the last one added to the array so get the parent for that item to set the new current parent
    var parentId: string = itemMap[itemIds[itemIds.length - 1]].ParentId!;

    //Delete the data from Dynamo
    DeleteItems(
      itemIds,
      (userCognitoAttrubutes as cognitoUserAttributes).sub!,
      subscriptionExpired
    );

    //loop over itemMap and skip ones that need to be deleted
    for (const key in itemMap) {
      if (!itemIds.includes(key)) {
        itemHashMap[key] = itemMap[key];
      }
    }
    parentHashMap = recalculateParentMap(itemHashMap);

    //pass item and parent maps back along with the list of items to delete
    return { itemHashMap, parentHashMap, parentId };
  }

  function recalculateParentMap(
    i: Record<string, itemDataFromDynamo> = itemMap
  ) {
    //Create new map to replace current
    var parentHashMap: Record<string, Set<string>> = {};

    for (const key in i) {
      //Recalculate Parent Map
      if (i[key].ParentId != null) {
        if (parentHashMap[i[key].ParentId!] == null) {
          parentHashMap[i[key].ParentId!] = new Set<string>([i[key].ItemId!]);
        } else {
          parentHashMap[i[key].ParentId!].add(i[key].ItemId!);
        }
      }
    }
    return parentHashMap;
  }

  //Recursive function to get the location of an item
  function getItemLocation(itemId: string, arr: string[] = []): string[] {
    arr.unshift(itemId);
    //var a = itemMap[itemId].ParentId;
    if (itemId !== "MASTER") {
      //a += "|" + getItemLocation(itemMap[itemId].ParentId!);
      getItemLocation(itemMap[itemId].ParentId!, arr);
    }
    return arr;
  }

  //Recursive function to get all children of an item
  function getItemChildren(itemId: string, arr: string[] = []): string[] {
    //Add all the item Ids to the list
    if (parentMap[itemId] != null) {
      parentMap[itemId].forEach((s) => {
        arr.push(s);
        //Check if item is a parent and if so call getItemChildren on it
        if (parentMap[s] != null) {
          getItemChildren(s, arr);
        }
      });
    }
    return arr;
  }

  //Move item to another location
  async function moveItem(
    itemId: string,
    location: string,
    pMap?: Record<string, Set<string>>,
    iMap?: Record<string, itemDataFromDynamo>
  ) {
    if (iMap == null) {
      iMap = itemMap;
    }
    let itemData: itemDataFromDynamo = iMap[itemId];
    itemData.ParentId = location;
    await PostItem(
      itemData,
      (userCognitoAttrubutes as cognitoUserAttributes).sub!,
      subscriptionExpired
    )
      .then(() => {
        iMap![itemId].ParentId = location;
        var itemHashMap: Record<string, itemDataFromDynamo> = {};
        for (const key in iMap) {
          itemHashMap[key] = iMap![key];
        }
        var parentHashMap = recalculateParentMap(itemHashMap);
        //Call update database

        recalculateCurrentArray(
          parentHashMap!,
          itemHashMap[itemId].ParentId!,
          iMap!
        );
        //setItemMap(itemHashMap);
        //setParentMap(parentHashMap);
        //setCurParent(itemHashMap[itemId].ParentId!);
      })
      .catch((error) => {
        if (error == 0) {
          alert(
            "Error saving data. This issue can be a result of lost internet connection. Please check your connection and try again."
          );
        } else {
          alert(
            "Error moving item. Please try again and contact Admin@WheresOur.com if the issue persists. Error code = " +
              error
          );
        }
      });
  }

  //Move item to another location
  function generateLocationStructure(
    parentId: string = "MASTER",
    obj: Record<string, { children: Set<string>; level: number }> = {},
    level: number = 1
  ) {
    //Get children of parent
    var children = parentMap[parentId];
    //If parent has children add to object
    obj[parentId] = { children, level };

    if (children != null) {
      level++;
      children.forEach((child) => {
        generateLocationStructure(child, obj, level);
      });
    }
    return obj;
  }

  function recalculateCurrentArray(
    pMap: Record<string, Set<string>>,
    parentId: string,
    iMap: Record<string, itemDataFromDynamo>,
    searchActiveVal?: boolean
  ) {
    setItemsInView(parseInt(process.env.REACT_APP_ITEM_VIEW_INTERVAL!));
    if (searchActiveVal == null) {
      searchActiveVal = searchActive;
    }
    var itemArray: itemDataFromDynamo[] = [];
    //Only add items to the array if there are items to get
    if (pMap[parentId] != null) {
      pMap[parentId].forEach((item) => {
        itemArray.push(iMap[item]);
      });
    }
    if (searchActiveVal === false) {
      setCurItemArray(itemArray);
    } else {
      itemArray = [];
      curItemArray.map((item) => {
        itemArray.push(iMap[item.ItemId]);
      });
      setCurItemArray(itemArray);
    }
    setCurParent(parentId);
    setParentMap(pMap);
    setItemMap(iMap);
    window.scrollTo(0, 0);
  }

  const SaveItem = (
    itemData: itemDataFromDynamo,
    uuid: string,
    close: boolean,
    fileUrlMainImage: string,
    userId: string,
    recalcCurrentArray: boolean
  ): Promise<number> => {
    var successStatus = 0;
    return new Promise(async (resolve, reject) => {
      //This call will add the data to dynamoDB and add the files to S3
      PostItem(itemData, userId, subscriptionExpired)
        //If dynamo updated successfully then add images to S3 and update local data
        .then(async (res: any) => {
          //Set resolver to 200 since post to database was successful
          successStatus = 0;
          //Save main image
          var retryAttempt = 0;
          var resp = 0;
          try {
            if (res.data.mainImageUrl != null && res.data.mainImageUrl !== "") {
              do {
                resp = await saveImageToS3(
                  res.data.mainImageUrl,
                  fileUrlMainImage,
                  uuid,
                  "mainimg.jpeg",
                  userId
                );
                if (resp >= 200 && resp < 299) {
                  retryAttempt = 1000; //Don't want to retry in this case
                } else {
                  retryAttempt++;
                }
              } while (retryAttempt < 1); //We will retry once
              if (resp === undefined || resp < 200 || resp > 299) {
                successStatus = 1;
              }
              itemData.Image = itemData.Image?.replace("{CLIENTID}", userId);
            }

            //recalculateCurrentArray(parentMap, itemData.ParentId, itemMap);
          } catch (e) {
            console.error(e);
          }
          if (itemMap[itemData.ItemId] != null) {
            HandleSuccessfulHttpPut(itemData, recalcCurrentArray);
          } else {
            HandleSuccessfulHttpPost(itemData, recalcCurrentArray);
          }

          //Update searchMap
          var searchHashMap: Record<string, Set<string>> = {};
          for (const key in searchMap) {
            searchHashMap[key.toLowerCase()] = searchMap[key.toLowerCase()];
            //Delete item from searchMap to prevent matches on old data
            searchHashMap[key.toLowerCase()].delete(itemData.ItemId);
          }
          //Add item to searchMap with Name as Key
          if (itemData.Name != null && itemData.Name !== "") {
            if (searchHashMap[itemData.Name.toLowerCase()]) {
              searchHashMap[itemData.Name.toLowerCase()].add(itemData.ItemId);
            } else {
              searchHashMap[itemData.Name.toLowerCase()] =
                new Set<string>().add(itemData.ItemId);
            }
          }
          //Add item to searchMap with Tags as Key
          if (itemData.Tags.length > 0) {
            itemData.Tags.forEach((value, index) => {
              if (searchHashMap[value.toLowerCase()]) {
                searchHashMap[value.toLowerCase()].add(itemData.ItemId);
              } else {
                searchHashMap[value.toLowerCase()] = new Set<string>().add(
                  itemData.ItemId
                );
              }
            });
          }
          setSearchMap(searchHashMap);
          if (close) {
            setCurItemData({});
            setupgradedItemFormOpen(false);
          } else {
            setCurItemData({});
          }
          //Check if image exists in S3
          if (res.data.mainImageUrl != null && res.data.mainImageUrl !== "") {
            try {
              await axios.get(itemData.Image!+"_mainimg.jpeg");
              resolve(0);
            } catch (error) {
              resolve(1);
            }
          } else {
            resolve(2); //No image in upload
          }
        })
        .catch((error) => {
          reject(error);
        });
    });
  };

  function HandleSuccessfulHttpPost(
    itemData: itemDataFromDynamo,
    recalcCurrentArray: boolean
  ) {
    //Update the item hashmap with the new item
    var itemHashMap: Record<string, itemDataFromDynamo> = {};
    for (const key in itemMap) {
      itemHashMap[key] = itemMap[key];
    }
    itemHashMap[itemData.ItemId] = itemData;

    var parentHashMap: Record<string, Set<string>> = {};
    for (const key in parentMap) {
      parentHashMap[key] = parentMap[key];
    }

    if (parentHashMap[itemData.ParentId!] == null) {
      parentHashMap[itemData.ParentId!] = new Set<string>();
    }
    parentHashMap[itemData.ParentId!] = parentHashMap[itemData.ParentId!].add(
      itemData.ItemId!
    );
    if (recalcCurrentArray) {
      recalculateCurrentArray(parentHashMap, curParent, itemHashMap);
    }

    setItemMap(itemHashMap);
    setParentMap(parentHashMap);
  }
  function HandleSuccessfulHttpPut(
    itemData: itemDataFromDynamo,
    recalcCurrentArray: boolean
  ) {
    //Update the item hashmap with the updated item data
    var itemHashMap: Record<string, itemDataFromDynamo> = {};
    for (const key in itemMap) {
      if (key === itemData.ItemId) {
        itemHashMap[key] = itemData;
      } else {
        itemHashMap[key] = itemMap[key];
      }
    }
    var parentHashMap: Record<string, Set<string>> = {};
    for (const key in parentMap) {
      parentHashMap[key] = parentMap[key];
    }
    if (parentHashMap[itemData.ParentId!] == null) {
      parentHashMap[itemData.ParentId!] = new Set<string>();
    }
    parentHashMap[itemData.ParentId!] = parentHashMap[itemData.ParentId!].add(
      itemData.ItemId!
    );
    if (recalcCurrentArray) {
      recalculateCurrentArray(parentHashMap, curParent, itemHashMap);
    }

    setItemMap(itemHashMap);
    setParentMap(parentHashMap);
  }

  function CancelSearch() {
    setItemSearchInput("");
    setItemSearchValue("");
    setLocationSearchInput("");
    setLocationSearchValue("");
    //recalculateCurrentArray(parentMap, curParent, itemMap, false);
    setSearchActive(false);
    setSearchItemsInUse(false);
    setItemsInView(parseInt(process.env.REACT_APP_ITEM_VIEW_INTERVAL!));
  }

  function logoutUser() {
    CancelSearch();
    setItemDataFromDynamo(undefined);
    setNewItemPopupOpen(false);
    setEditItemPopupOpen(false);
    setupgradedItemFormOpen(false);
    setItemToDelete("");
    setItemToMove("");
    setCurItemData({});
    setCurItemArray([]);
    setIsSearchResult(false);
    setCurParent("MASTER");
    setParentToBe("MASTER");
    setItemMap({});
    setParentMap({});
    setSearchMap({});
    setLoggedInUserCognitoIdToken("");
    setItemSearchInput("");
    setItemSearchValue("");
    setLocationSearchInput("");
    setLocationSearchValue("");
    localStorage.removeItem("CognitoIdToken");
    localStorage.removeItem("UserCognitoAttrubutes");
    localStorage.removeItem("expireTime");
    localStorage.removeItem("sessionExpireTime");
    logout();
  }

  return (
    <UserDataContext.Provider
      value={{
        itemDataFromDynamo,
        setItemDataFromDynamo,
        newItemPopupOpen,
        setNewItemPopupOpen,
        curItemData,
        setCurItemData,
        curItemArray,
        setCurItemArray,
        isSearchResult,
        setIsSearchResult,
        curParent,
        setCurParent,
        itemMap,
        setItemMap,
        parentMap,
        setParentMap,
        editItemPopupOpen,
        setEditItemPopupOpen,
        searchMap,
        setSearchMap,
        parentToBe,
        setParentToBe,
        itemToDelete,
        setItemToDelete,
        changeParent,
        deleteItem,
        getItemLocation,
        getItemChildren,
        generateLocationStructure,
        itemToMove,
        setItemToMove,
        moveItem,
        upgradedItemFormOpen,
        setupgradedItemFormOpen,
        searchActive,
        setSearchActive,
        logoutUser,
        recalculateCurrentArray,
        itemSearchInput,
        setItemSearchInput,
        itemSearchValue,
        setItemSearchValue,
        locationSearchInput,
        setLocationSearchInput,
        locationSearchValue,
        setLocationSearchValue,
        searchOpen,
        setSearchOpen,
        popupMessage,
        setPopupMessage,
        displayNav,
        setDisplayNav,
        itemToMoveOrUse,
        setItemToMoveOrUse,
        SaveItem,
        searchItemsInUse,
        setSearchItemsInUse,
        CancelSearch,
        displayNavLinks,
        setDisplayNavLinks,
        isLoading,
        setIsLoading,
        itemsInView,
        setItemsInView,
        checkoutPopupOpen,
        setCheckoutPopupOpen,
        subscriptionExpired,
        setSubscriptionExpired,
        subscriptionEndDate,
        setSubscriptionEndDate,
        subscriptionStatus,
        setSubscriptionStatus,
        subscriptionCancelAtPeriodEnd,
        setSubscriptionCancelAtPeriodEnd,
      }}
    >
      {props.children}
    </UserDataContext.Provider>
  );
};

function useUserDataContext() {
  const context = useContext(UserDataContext);
  if (!context) {
    throw new Error("useUserContext must be used within UserContextProvider");
  }
  return context;
}

export { UserData, useUserDataContext };
