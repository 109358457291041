import {createContext, useContext, useState} from 'react'

export type loginStageType = 'none'|'login'|'register'|'forgot_password';

export type LoginStagesContext = {
    loginStage: loginStageType;
    setLoginStage:React.Dispatch<React.SetStateAction<loginStageType>>
    loginMessage: string|null
    setLoginMessage: React.Dispatch<React.SetStateAction<string|null>>
}

type LoginStageContextProvider = {
    children: React.ReactNode;
}

const LoginStageContext = createContext<LoginStagesContext | null>(null);

function LoginStageContextProvider({children}:LoginStageContextProvider) {
    const [loginStage, setLoginStage] = useState<loginStageType>('none');
    const [loginMessage, setLoginMessage] = useState<string|null>(null);

  return (
    <LoginStageContext.Provider value = {{loginStage, setLoginStage,loginMessage, setLoginMessage}}>
        {children}
    </LoginStageContext.Provider>
  )
}

function useLoginStageContext(){
    const context = useContext(LoginStageContext)
    if(!context){
        throw new Error("useLoginStageContext must be used within LoginStageContextProvider");
    }
    return context;
}

export {LoginStageContextProvider, useLoginStageContext}