import "../Styles/navStyles.css";
import { Link, useMatch, useResolvedPath } from "react-router-dom";
import {
  StyledHeaderNav,
  StyledHeaderNonNav,
} from "../styledComponents/HeaderNav.styled";

type navBarLink = {
  to: string;
  label: string;
};

export function HeaderNavFAQ() {
  return (
    <StyledHeaderNav displaynav={"notSticky"} className="nav">
      <StyledHeaderNonNav>
        <ul>
          <CustomLink to="/Home" label="Home" />
          <CustomLink to="/FAQ" label="Guide" />
          <CustomLink to="/MyItems" label="My Items" />
        </ul>
      </StyledHeaderNonNav>
    </StyledHeaderNav>
  );
}

function CustomLink({ to, label }: navBarLink) {
  const resolvedPath = useResolvedPath(to);
  const isActive = useMatch({ path: resolvedPath.pathname, end: true });
  return (
    <li className={isActive ? "active" : ""}>
      <Link to={to}>{label}</Link>
    </li>
  );
}
