import axios, { Axios } from "axios";

//Format the image for upload to S3
export function formatImageForS3(ev: any, imageMaxWidth: number) {
  return new Promise((resolve, reject) => {
    const imgElement = document.createElement("img");
    imgElement.src = ev.target!.result!.toString();

    imgElement.onload = (e: Event) => {
      const imgElement = document.createElement("img");
      imgElement.src = ev.target!.result!.toString();

      const canvas = document.createElement("canvas");
      const scaleSize = imageMaxWidth / (e.currentTarget as HTMLImageElement).width;
      var context = canvas.getContext("2d");

      //set width and height based on env variables and calculated scale
      canvas.width = imageMaxWidth;
      canvas.height = (e.currentTarget as HTMLImageElement).height * scaleSize;

      //Draw the image
      context!.drawImage(imgElement, 0, 0, canvas.width, canvas.height);
      resolve(context!.canvas.toDataURL("image/jpeg", 0.75));
    };
  });
}

//Convert file into URL since S3 requires a file not a URL
export function dataURLtoFile(dataurl: string, filename: string) {
  var arr = dataurl.split(","),
    mime = arr[0].match(/:(.*?);/)![1],
    bstr = atob(arr[arr.length - 1]),
    n = bstr.length,
    u8arr = new Uint8Array(n);
  while (n--) {
    u8arr[n] = bstr.charCodeAt(n);
  }
  return new File([u8arr], filename, { type: mime });
}

export async function saveImageToS3(
  S3Url: string,
  fileUrl: string,
  uuid: string,
  filename: string,
  userId:string
):Promise<number> {
  var resp:number|undefined = 0;
  await axios.put(
    S3Url,
    dataURLtoFile(
      fileUrl,
      String.prototype.concat(
        "user_images/",
        userId,
        "/",
        uuid,
        "_" + filename
      )
    ),
    {
      headers: {
        "Content-Type": "image/jpeg",
        "Cache-Control": "max-age=31536000",
      },
      timeout:4000
    }
  ).then((res)=>{
    resp = res.status
  }).catch((error)=>{
    if(error.code === 'ECONNABORTED'){
      console.log('S3 push failed due to timeout')
    }
    if(axios.isAxiosError(error)){
      resp = error.response?.status
    }else{
      resp = 0
    }
  });
  return resp
}
