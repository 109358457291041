import styled from "styled-components";

export const StyledTagsInputDiv = styled.div`
  display: inline-block;
  width: 75%;
  background-color: #ffffff;
  padding: 5px 2px;
  @media (max-width: 600px) {
    width:95%;
  };
`;

export const StyledTagsInputInput = styled.input`
  width: 75%;
  padding: 12px 20px;
  margin: 8px 0;
  border-radius: 4px;
  background-color: #ffffff;
  border: none;
  cursor: pointer;
  font-size: 1em;
  font-family:Arial, Helvetica, sans-serif;
  resize:vertical;
`;

export const StyledTagsInputTagItemContainer = styled.div`
    background-color: #8dabe2;
    display: inline-block;
    padding:.5em .75em;
    border-radius: 20px;
    margin:2px;
`;


