import { Translate } from "aws-sdk";
import styled from "styled-components";

export const ImageLink = styled.span``;

interface Props {
  x: number;
  y: number;
}

export const ImageDiv = styled.div<Props>`
  display: none;
  ${ImageLink}:hover + & {
    display: flex;
    position: fixed;
    overflow: hidden;
    left: ${(props) => (props.x > 0 ? (props.x + 10).toString() + "px" : 0)};
    top: ${(props) => (props.y > 0 ? (props.y + 30).toString() + "px" : 0)};
    transform: ${(props) =>
      props.x > window.screen.availWidth / 2
        ? `translate(-100%)`
        : `translate(0%)`};

    @media (max-width: 600px) {
    left:50%;
    transform:Translate(-50%);
    top: ${(props) => (props.y > 0 ? (props.y + 50).toString() + "px" : 0)};
  }
}
`;
